import { EventEmitter, NgZone, Optional, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { CountryInfo, CountryInfoWithCode, LanguageCountryInfoWithCode } from './model/CountryInfo';
import { LanguageInfo, LanguageInfoWithCode} from './model/LanguageInfo';
import { AppConfig, Countries, Country, BackendService, ServicePoint, SearchServicePointsResult, Capability, MessageDetails, MessageDetail } from './backend.service';
import { Utils } from './common/utils';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Uom } from './model/Uom';
import { EncodeDecode } from './common/encode-decode';
import { UntypedFormControl } from '@angular/forms';
import { PageAttributes } from './app.type';
import { Meta } from '@angular/platform-browser';

declare const window: any;
declare const OneTrust: any;

const arraytoCommaSeperated = (capabilities) => capabilities ? capabilities.filter(capability => capability).join(',') : null;

export interface AlertMessage {
    image: string
    message1: string
    message2: string
}

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    //private queryString = require('query-string');
    // package.json --> "query-string": "^6.11.1",

    isError = false;

    private _appConfig: AppConfig;
    public transcationId;
    get appConfig() { return this._appConfig; }
    set appConfig(appConfig: AppConfig) { this._appConfig = appConfig; }
    paymentOptions: Array<Capability>;
    collectOptions: Array<Capability>;
    paymentOptionsCustom: Array<Capability>;
    collectOptionsCustom: Array<Capability>;

    private _countries: Countries;
    get countries() { return this._countries; }
    set countries(countries: Countries) { this._countries = countries; }

    private _languages: any;
    get languages() { return this._languages; }
    set languages(languages: any) { this._languages = languages; }

    private _cookieCheckDisabled = false;
    private _cookiesAllowed = false;
    private _language: LanguageInfo;
    private _languageCountry: CountryInfo;

    private kilometresUom: Uom = { code: 'km', description: 'Kilometres' };
    private milesUom: Uom = { code: 'mi', description: 'Miles' };
    uoms: Array<Uom> = [this.kilometresUom, this.milesUom];
    private _uom: Uom = this.milesUom;

    private _lightVersion: boolean = false;
    private _selectedCountry: Country = null;

    mobileHiding = false;

    public uomChanged = new EventEmitter<Uom>();
    public languageCountryChanged = new EventEmitter<CountryInfo>();
    public languageChanged = new EventEmitter<LanguageInfo>();
    public searchResultLoaded = new EventEmitter<void>();
    public servicePointsFilterTriggered = new EventEmitter<Array<ServicePoint>>();
    public qrCodePromotionChanged = new EventEmitter<void>();
    public servicePointSelected = new EventEmitter<ServicePoint>();
    public mapMovedOrZoomed = new EventEmitter<boolean>();
    public resultListSearched = new EventEmitter<void>();
    public svpPromotionClosed = new EventEmitter<void>();
    public promotionBannerClosed = new EventEmitter<void>();
    public shareButtonDisabled = new EventEmitter<any>();
    public bingMapLoaded = new EventEmitter<any>();
    public isFilterSelected: boolean = false;
    public isShowAlertMsg: boolean = false;
    public queryParamChanged: boolean = false; //RTC - 502831
    public isSearchClicked: boolean = false;
    private cookiesExpiration = new Date(3000, 1, 1, 1, 1, 1, 1);
    public oneTrustScriptLoaded = new EventEmitter<any>();

    private onLoadQueryParams: Params;

    private _languageResolved: Promise<void>;

    private _languageResolver: any;

    public navigationLinkClicked = new EventEmitter<string>();

    public handlingDefaultCapab: Capability;

    public jsApiPageParam: Params;

    //public isApiResultsPageOpenedInitial: boolean = false;

    public isApiAddressPreSelected: boolean = false;

    public clientAppCode: String = "";

    public cookieDisplayStatus: boolean = true;

    /*public cookieLinkDisplayStatus: boolean = true;*/ //694365

    public ppcCodesInUrl: String = "";

    public qrPPCCodesOnlyInUrl: Boolean = false;

    public enableQRCodeByDefault: Boolean = false;

    public isResultReachedFromHomeLanding: Boolean = false;

    public isQrCodeAccessed: boolean = false;

    public isParameterizedExtClientCallValidationFailed: boolean = false;

    public codeToUseDirectionsPrint: string = 'en-GB$Latn';

    public isParentPageDirections: boolean = false;

    public jsApiPageAttributes: PageAttributes = {};

    public svpStatus: string = '';
    public messageDetails: MessageDetails;
    public expressMessage: MessageDetail;
    public expressMessageWithLink: MessageDetail;
    public messageDetailFetched = new EventEmitter<void>();

    public ppcsArray: string[] = null;

    public isUserInputLocationNeeded: boolean = true;

    public userSearchLocation: any;

    public showTravelMode: boolean = true;

    public pageReloaded: boolean = false;

    public directionCountryCode: string = '';

    public isCountryFreezed : boolean = false;

    public _userCountry: Country = null;//791167

    public isCountryNameROW : boolean = false;//791167

    public userCountryFromUrl: string = '';

    public countryPopulatedByDetectCountry: boolean = false;

    public isMapMovedToSea: boolean = false;

    public isSpDetail2Active: boolean = false;

    public mapMovedonMobile = new EventEmitter<void>();

    get languageResovled() {
        return this._languageResolved;
    }

    private funcCookieCateoryId = '';
    public alert: AlertMessage;
    public hasMapMovedOrZoomed: boolean;

    private prevpromotionFlag: boolean = false;
    private prevQrFlag: boolean = false;

    public isMapConsentGiven: boolean = false;

    setCookie(key, value) {
        const https: boolean = window.location.protocol.startsWith('https');
        this.cookies.set(key, value, this.cookiesExpiration, '/', null,
            https, 'Lax');
    }

    /**
     * Use of this method is to set cookies for active groups
     * @param key 
     * @param value 
     */
    /*setActiveGroupCookie(key, value) {
        try {
            if (OptanonActiveGroups && OptanonActiveGroups.includes(this.funcCookieCateoryId)) {
                this.setCookie(key, value);
            }
        } catch (Error) {
            if ((<String>Error.message).indexOf("OptanonActiveGroups is not defined") >= 0) {
                // Supressing the error message here as this is expected
            } else {
                console.error(Error.message);
            }
        }
    }*/

    getCurrentLangCode() {
        return this.translateService.currentLang ? this.translateService.currentLang.split('$')[0] : 'en-US';
    }

    get cookieCheckDisabled() {
        return this._cookieCheckDisabled;
    }

    set cookieCheckDisabled(val: any) {
        window.sessionStorage.setItem('noCookies', val.toString());
        this._cookieCheckDisabled = val;
        if (val) {
            this._cookiesAllowed = false;
        }
    }

    get selectedCountry(): Country {
        return this._selectedCountry;
    }

    set selectedCountry(country: Country) {
        this._selectedCountry = country;
        if (this.isCookiesConsentGiven() && !this.isFunctionalCookiesDisabled()) {
            if (country) {
                var countryInfoWithCode: CountryInfoWithCode = {
                    countryCode2: this._selectedCountry.value
                };
                this.setCookie('sc', JSON.stringify(countryInfoWithCode));
            } else {
                this.cookies.delete('sc');
            }
        } else {
            //console.error("OnetrustActiveGroups is undefined");
        }

    }
    
    get userCountry(): Country {
        return this._userCountry;
    }

    set userCountry(country: Country){
        this._userCountry = country;
        if (this.isCookiesConsentGiven() && !this.isFunctionalCookiesDisabled()) {
            if (country) {
                this.setCookie('uc', (country));
            } else {
                this.cookies.delete('uc');
            }
        } else {
            //console.error("OnetrustActiveGroups is undefined");
        }
    }

    get cookiesAllowed() {
        return this._cookiesAllowed;
    }

    set cookiesAllowed(onOff) {
        this._cookiesAllowed = onOff;
        if (onOff === true) {
            //this.setCookie('cookiesAllowed', onOff.toString()); // commented out as it is managed by onetrust
        } 
    }

    get lightVersion() {
        return this._lightVersion;
    }

    set lightVersion(onOff) {
        this._lightVersion = onOff;
        
        if (this.isCookiesConsentGiven() && !this.isFunctionalCookiesDisabled()) {
            this.setCookie('lv', onOff.toString());
        } else {
            //console.error("OnetrustActiveGroups is undefined");
        }
        
    }

    get languageCountry(): CountryInfo {
        return this._languageCountry;
    }

    set languageCountry(languageCountry: CountryInfo) {
        this.setLanguageCountry(languageCountry);
    }

    setLanguageCountry(languageCountry: CountryInfo, emit = true, reloadMap = true, isSetTranslations = true) {
        if (languageCountry) {
            //console.log("language country is myDHL Plus",this.isMyDHLPlus());
            this._languageCountry = languageCountry;
            //var countryInfoWithCode :CountryInfoWithCode = {countryCode2:'',value:''};
            //countryInfoWithCode.countryCode2 = this._languageCountry.countryCode2;
            if (this.isCookiesConsentGiven() && !this.isFunctionalCookiesDisabled()) {
                var languageCountryInfoWithCode: LanguageCountryInfoWithCode = { countryCode2: this._languageCountry.countryCode2, isoLanguage: this._language.isoLanguage };
                this.setCookie('langCountry', JSON.stringify(languageCountryInfoWithCode));
            } else {
                //console.error("OnetrustActiveGroups is undefined");
            }
            //Below block is commented as part of 739047
            /*if (languageCountry.unitOfLength === 'KILOMETER') {
                this.setUom(this.kilometresUom, emit);
            } else if (languageCountry.unitOfLength === 'MILES') {
                this.setUom(this.milesUom, emit);
            }*/

            if (isSetTranslations) {
                this.setTranslations(reloadMap);
            }

            if (emit) {
                this.languageCountryChanged.emit(languageCountry);
            }
        }
    }

    get language(): LanguageInfo {
        return this._language;
    }

    set language(l: LanguageInfo) {
        this.setLanguage(l, true, true, true, true, true);
    }

    setLanguage(l, emit = true, reloadMap = true, propagate = true, isSetTranslations = true, messageDetail = false) {
        if (l) {
            this._language = l;

            if (!Utils.isJsApi()) {
                if (this._language.textDirection === 'RTL') {
                    window.document.querySelector('body').setAttribute('dir', 'RTL');
                    window.document.querySelector('body').classList.add('RTL');
                } else {
                    window.document.querySelector('body').setAttribute('dir', 'LTR');
                    window.document.querySelector('body').classList.remove('RTL');
                }
            }

            if (propagate) {
                const ctrys = this.languages[l.isoLanguage].countryInfos;
                this.setLanguageCountry(ctrys.find(ctry => ctry.countryCode2 === l.countryCode), emit, reloadMap, isSetTranslations);
            }


            if (emit) {
                this.languageChanged.emit(l);
            }

            if (this.isCookiesConsentGiven() && !this.isFunctionalCookiesDisabled()) {
                const truncated = Object.assign({}, l);
                delete truncated.countryInfos;
                var languageInfoWithCode: LanguageInfoWithCode = { isoLanguage: truncated.isoLanguage };
               this.setCookie('lang', JSON.stringify(languageInfoWithCode));
            } else {
                //console.error("OnetrustActiveGroups is undefined");
            }
        }
    }

    get uom(): Uom {
        return this._uom;
    }

    set uom(uom: Uom) {
        this.setUom(uom);
    }

    setUom(uom: Uom, emit = true) {
        if (uom && this._uom.code !== uom.code) {
            if (emit) {
                this.uomChanged.emit(uom);
            }
            this._uom = uom;
            if (this.isCookiesConsentGiven() && !this.isFunctionalCookiesDisabled()) {
                this.setCookie('uom', JSON.stringify(uom));
            } else {
                //console.error("OnetrustActiveGroups is undefined");
            }
            
        }
    }

    isRTL() {
        if (this._language && this._language.textDirection === 'RTL') {
            return true;
        }

        return false;
    }

    private _searchResult: SearchServicePointsResult;
    set searchResult(searchResult: SearchServicePointsResult) {
        this.qrPPCCodesOnlyInUrl = false;
        this._searchResult = searchResult;
        this.selectedServicePoint = null;
        this.isShowAlertMsg = false;
        this.prevpromotionFlag = this.spPromotionFilter.value;
        this.prevQrFlag = this.acceptQrCodeFilter.value;
        if (this._searchResult.servicePoints) {
            this._searchResult.hasQrCode = this._searchResult.servicePoints.some(servicePoint => servicePoint.servicePointCapabilities.labelFree);
            this._searchResult.hasSpPromotion = this._searchResult.servicePoints.some(servicePoint => (servicePoint.promotion || (servicePoint.partner && servicePoint.partner.promotion)) ? true : false);
            this.filteredSearchResult = Object.assign({}, this._searchResult);
            
            if(this.hasMapMovedOrZoomed){
                if(!this._searchResult.hasQrCode){
                    this.acceptQrCodeFilter.setValue(false);
                }
                if(!this._searchResult.hasSpPromotion) {
                    this.spPromotionFilter.setValue(false);
                }
            }
            this.filterServicePoints(false);
        } else {
            this.filteredSearchResult = this._searchResult;
        }

        /*if (this._searchResult.hasQrCode) {
            this.acceptQrCodeFilter.enable({ onlySelf: true });
        } else {
            this.acceptQrCodeFilter.setValue(false);
            this.acceptQrCodeFilter.disable({ onlySelf: true });
        }*/
        //TODO set qrCode and promotion flag
        if (this.hasMapMovedOrZoomed) {
            this.checkQRPromotionAlertMessage(this._searchResult.hasQrCode, this._searchResult.hasSpPromotion);
            this.hasMapMovedOrZoomed = false;
        }
        if(this.qrPPCCodesOnlyInUrl){ // Removed this for 788179|| (this.acceptQrCodeFilter.value && !this._searchResult.hasQrCode)
            this.acceptQrCodeFilter.setValue(false);
            this.acceptQrCodeFilter.disable({ onlySelf: true });
        } else {
            this.acceptQrCodeFilter.enable({ onlySelf: true });
        }

        if (this._searchResult.hasSpPromotion) {//3.1 - BR1
            this.spPromotionFilter.enable({ onlySelf: true });
        } else {
            this.spPromotionFilter.setValue(false);
            this.spPromotionFilter.disable({ onlySelf: true });
        }
    }
    get searchResult(): SearchServicePointsResult { return this._searchResult; }

    filteredSearchResult: SearchServicePointsResult;
    acceptQrCodeFilter = new UntypedFormControl(false);
    spPromotionFilter = new UntypedFormControl(false);

    filterServicePoints(triggeredByFilter: boolean = true) {
        let servicePoints = this._searchResult.servicePoints;
        if (this.acceptQrCodeFilter.value || this.spPromotionFilter.value) {
            servicePoints = this._searchResult.servicePoints.filter(servicePoint => {
                if (this.acceptQrCodeFilter.value && this.spPromotionFilter.value) {
                    return servicePoint.servicePointCapabilities.labelFree &&
                        (servicePoint.promotion || (servicePoint.partner && servicePoint.partner.promotion));
                } else if (this.acceptQrCodeFilter.value) {
                    return servicePoint.servicePointCapabilities.labelFree;
                } else if (this.spPromotionFilter.value) {
                    return servicePoint.promotion || (servicePoint.partner && servicePoint.partner.promotion);
                } else {
                    return true;
                }
            });

            // commented this for 788179
            /*if(this.hasMapMovedOrZoomed && servicePoints && servicePoints.length !=0) { 
                servicePoints = this._searchResult.servicePoints;
            }*/
        }
        if (!this.filteredSearchResult.servicePoints) {
            this.filteredSearchResult.servicePoints = [];
        }
        this.filteredSearchResult.triggeredByFilter = this.filteredSearchResult && this.filteredSearchResult.servicePoints && triggeredByFilter && servicePoints && servicePoints.length !== this.filteredSearchResult.servicePoints.length;
        if (this.filteredSearchResult.triggeredByFilter) {
            this.notifyServicePointsFilterTriggered(servicePoints);
        }

        if (servicePoints && !triggeredByFilter) { //Included this block for RTC 566002 
            this.filteredSearchResult.servicePoints = servicePoints;
        }
    }

    filterServicePointsInitial() {
        this.isFilterSelected = true;
        this.notifyQrCodePromotionChanged();
    }

    get backendUrl(): string {
        return this.backend.backendUrl;
    }

    selectedServicePoint: ServicePoint;
    printData: any;
    placeId: string;
    providerId: string;
    sessionToken: string;
    toReloadResultsPage = false;

    constructor(private backend: BackendService, private translateService: TranslateService, private cookies: CookieService, @Optional() private ngZone: NgZone) {
        // this language will be used as a fallback when a translation isn't found in the current language
        this.translateService.setDefaultLang('en-US');
        this.setJsApiPageAttributes();
    }

    initialize(): Promise<void> {
        this.backend.initialize();
        return new Promise<void>((resolve, reject) => {
            this.callBackend().then(() => {

                this._languageResolved = new Promise(resolve => {
                    this._languageResolver = resolve;
                });

                this.onLoadQueryParams = Utils.getQueryParams(this);

                const cookiesAllowed = this.cookies.get('cookiesAllowed');
                if (cookiesAllowed && cookiesAllowed !== 'undefined') {
                    this._cookiesAllowed = (cookiesAllowed === 'true');
                }

                if (['no', 'n'].includes(this.onLoadQueryParams['cookies']) ||
                    window.sessionStorage.getItem('noCookies') === 'true') {
                    this.cookieCheckDisabled = true;
                } else if (['yes', 'y'].includes(this.onLoadQueryParams['cookies'])) {
                    this.cookieCheckDisabled = false;
                }

                if (Utils.isJsApi()) {
                    this.mobileHiding = true;
                }

                this.resolveLanguage();
                this.resolveLanguageCountry();
                this.resolveSelectedCountry();
                this.resolveUom();
                this.resolveLightVersion();

                this.translateService.onLangChange.subscribe(() => {
                    let paymentCode0Key = 'spl.details.rs.drop_off';
                    let collectCode0Key = 'spl.details.rs.collection';
                    if (window.gspl.params.isLabelFree) {
                        paymentCode0Key = 'spl.search.choose_payment_option';
                        collectCode0Key = 'spl.search.choose_collection_option';
                    }
                    this.paymentOptions = [];
                    this.collectOptions = [];
                    this.paymentOptionsCustom = [];
                    this.collectOptionsCustom = [];
                    if (this.selectedCountry &&
                        this.selectedCountry.capabilityCustom &&
                        this.selectedCountry.capabilityCustom.payments &&
                        this.selectedCountry.capabilityCustom.collects) {
                        Promise.all(
                            [this.translateCapabilities(this.appConfig.capabilities.payments, this.paymentOptions, paymentCode0Key),
                            this.translateCapabilities(this.appConfig.capabilities.collects, this.collectOptions, collectCode0Key),
                            this.translateCapabilities(this.selectedCountry.capabilityCustom.payments, this.paymentOptionsCustom, paymentCode0Key),
                            this.translateCapabilities(this.selectedCountry.capabilityCustom.collects, this.collectOptionsCustom, collectCode0Key)
                            ]
                        ).then(() => resolve()).catch(error => reject());
                    } else {
                        Promise.all(
                            [this.translateCapabilities(this.appConfig.capabilities.payments, this.paymentOptions, paymentCode0Key),
                            this.translateCapabilities(this.appConfig.capabilities.collects, this.collectOptions, collectCode0Key)
                            ]
                        ).then(() => resolve()).catch(error => reject());
                    }
                    /*if(this.selectedCountry && 
                        this.selectedCountry.capabilityCustom && 
                        this.selectedCountry.capabilityCustom.payments && 
                        this.selectedCountry.capabilityCustom.collects){*/
                    /* this.selectedCountry.capabilityCustom.payments =  [{
                         "code": "81",
                         "labelKey":"spl.search.capability.81",
                         "downstreams": [{
                             "code": "73",
                             "labelKey":"spl.search.capability.73",
                         },{
                             "code": "74",
                             "labelKey":"spl.search.capability.74",
                         }, {
                             "code": "75,76",
                             "labelKey":"spl.search.capability.7576",
                         }]
                     }, {
                         "code": "88",
                         "labelKey":"spl.search.capability.88",
                         "downstreams": [{
                             "code": "73",
                             "labelKey":"spl.search.capability.73",
                         },
                         {
                             "code": "72",
                             "labelKey":"spl.search.capability.72"
                         }]
                     }, {
                         "code": "82",
                         "labelKey":"spl.search.capability.82",
                         "downstreams": [{
                             "code": "74",
                             "labelKey":"spl.search.capability.74"
                         }, {
                             "code": "75,76",
                             "labelKey":"spl.search.capability.7576"
                         }]
                     }, {
                         "code": "90",
                         "labelKey":"spl.search.capability.90",
                         "downstreams": [{
                             "labelKey":"spl.search.capability.74",
                             "code": "74"
                         }, {
                             "labelKey":"spl.search.capability.7576",
                             "code": "75,76"
                         }]
                     }];
                     this.selectedCountry.capabilityCustom.collects = [{
                         "code": "78,79",
                         "labelKey":"spl.search.sl.collect_shipment"
                     }, {
                         "code": "86,87",
                         "labelKey":"spl.search.sl.send_shipment"
                     },
                     {
                         "code": "91",
                         "labelKey":"spl.search.capability.91"
                     }];*/
                    /*Promise.all(
                        [this.translateCapabilities(this.selectedCountry.capabilityCustom.payments, this.paymentOptionsCustom, paymentCode0Key),
                        this.translateCapabilities(this.selectedCountry.capabilityCustom.collects, this.collectOptionsCustom, collectCode0Key)]
                    ).then(() => resolve()).catch(error => reject());*/
                    //}
                });
            }).catch(error => {
                if (error) {
                    console.error(error);
                }
                this.isError = true;
                reject();
            });
        });
    }

    private callBackend(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.backend.getAppConfig().then(appConfig => {
                this.appConfig = appConfig;
                this.funcCookieCateoryId = this.appConfig.gsplCookieCategory.filter(val => val.indexOf('functional') > -1)[0].split(":")[1];
                if (this.appConfig.jsapiv3Usegsplwsbkey) {
                    this.backend.wsbKey = this.appConfig.wsbKey;
                } else {
                    if (!this.backend.wsbKey && this.backend.clientAppCode) {
                        /*if(this.appConfig.jsapiv3Usegsplwsbkey){
                            this.backend.wsbKey = this.appConfig.wsbKey;
                        } else {*/
                        var data = JSON.stringify(this.appConfig.clientIdKeys).replace("{", '').replace("}", '');
                        if (data) {
                            data.split(",").forEach((element) => {
                                let elementValue = element.split(":")[0].split('"').join('');
                                if (elementValue === (btoa(this.backend.clientAppCode.toUpperCase()))) {
                                    this.backend.wsbKey = element.split(":")[1].split('"').join('');
                                }
                            });
                        }
                    }
                    if (Utils.isJsApi() && !this.backend.clientAppCode && this.backend.wsbKey) {
                        var data = JSON.stringify(this.appConfig.clientIdKeys).replace("{", '').replace("}", '');
                        if (data) {
                            data.split(",").forEach((element) => {
                                let elementValue = element.split(":")[1].split('"').join('');
                                if (elementValue === this.backend.wsbKey) {
                                    this.backend.clientAppCode = atob(element.split(":")[0].split('"').join(''));
                                   // this.clientAppCode = this.backend.clientAppCode;
                                }
                            });
                        }
                    }
                }

                // If the client app code validation is NOT required 
                // but still wants to by pass the wsb calls with gspl key and throw validation error.
                if (!this.appConfig.clientAppCodeValidation && !this.backend.wsbKey) {
                    this.backend.wsbKey = this.appConfig.wsbKey;
                }

                // If the client app code validation is required 
                // still wants to by pass the wsb calls with gspl key and throw validation error.
                if (!this.backend.wsbKey) {
                    this.backend.validationWsbKey = this.appConfig.wsbKey;
                }

                this.backend.initializeBackendUrlsWithWsb(this.appConfig.wsbEndpointUrl);
                const countriesPromise = this.backend.getCountries();
                const languagesPromise = this.backend.getLanguages();
                const checkWsbKeyPromise = this.backend.checkWsbKey()
                Promise.all([countriesPromise, languagesPromise, checkWsbKeyPromise]).then(data => {
                    this.countries = data[0];
                    this.languages = data[1];
                    resolve();
                }).catch(error => reject());
            }).catch(error => { console.log("error", error); reject(); });
        });
    }

    private resolveLanguage() {
        const languageCodeFromUrl: string = this.onLoadQueryParams['l'] || this.onLoadQueryParams['L'] || this.onLoadQueryParams['language'];
        if (languageCodeFromUrl) { // Language from URL
            this._language = this._languages[languageCodeFromUrl];
        } else { // Language from Cookie
            const languageCookie = this.cookies.get('lang');
            if (languageCookie && languageCookie !== 'undefined') {
                const languageCookieObj = <LanguageInfoWithCode>JSON.parse(languageCookie);
                this._language = this._languages[languageCookieObj.isoLanguage];
            }
        }
        if (!this._language) { // set default Language
            this._language = this.defaultLanguage;
        }
        if (!Utils.isJsApi()) {
            if (this._language.textDirection === 'RTL') {
                window.document.querySelector('body').setAttribute('dir', 'RTL');
                window.document.querySelector('body').classList.add('RTL');
            }
        }
    }

    public cookieSettingForLang() {//cookie settings changes
        if(!Utils.isJsApi()){
            try {
                var oneTrustCode = this.getOneTrustCode(this._language, this._languageCountry);
                //document.getElementById("#onetrust-consent-sdk").style.display = 'none';
                document.documentElement.lang=oneTrustCode;
                OneTrust.changeLanguage('');
                OneTrust.changeLanguage(oneTrustCode);
                //document.getElementById("#onetrust-consent-sdk").style.display = 'block';
            } catch (Error) {
            }
        } 
    }

    isFunctionalCookiesDisabled(): boolean {
        var clientAppCode = this.backend.clientAppCode ? this.backend.clientAppCode : this.clientAppCode
        if(this.appConfig.disableFunctionalCookiesClients && clientAppCode) {
            const clientAppCodeLower = clientAppCode.toLowerCase();
            
            const isClientDisabled = this.appConfig.disableFunctionalCookiesClients
                .map(client => client.toLowerCase())
                .includes(clientAppCodeLower);
            
            if (isClientDisabled) {
                const cookiesToDelete = ['lang', 'langCountry', 'sc', 'uom', 'lv', 'uc', 'travelMode'];
                
                cookiesToDelete.forEach(cookieName => {
                    if (this.cookies.get(cookieName)) {
                        this.cookies.delete(cookieName);
                    }
                });
                return true;
            }
        }
        return false;
    }

    public getOneTrustCode(langInfo: LanguageInfo, langCountry: CountryInfo) {
        var oneTrustCode = 'en';
        if(langInfo && langCountry && langInfo.countryInfos){
            langInfo.countryInfos.forEach(val => {
                if(val.id === langCountry.id){
                    oneTrustCode = val.oneTrustCode;
                    return oneTrustCode;
                }
            });
        }
        return oneTrustCode;
    }

    private get defaultLanguage(): LanguageInfo {
        const defaultLanguage = <LanguageInfo>this._languages['en'];
        if (defaultLanguage) {
            return defaultLanguage;
        } else {
            console.error('Missing default language "en" from ...');
            console.error(this._languages);
        }
        return null;
    }

    private resolveLanguageCountry() {
        const languageCountryCodeFromUrl: string = this.onLoadQueryParams['lc'] || this.onLoadQueryParams['Lc'] || this.onLoadQueryParams['languageCountryCode'];
        if (languageCountryCodeFromUrl) { // LanguageCountry from URL
            this._languageCountry = this._language.countryInfos.find(countryInfo => countryInfo.countryCode2 === languageCountryCodeFromUrl);
        } else { // LanguageCountry from Cookie
            const languageCountryCookie = this.cookies.get('langCountry');
            if (languageCountryCookie && languageCountryCookie !== 'undefined') {
                const languageCountryCookieObj = <LanguageCountryInfoWithCode>JSON.parse(languageCountryCookie);
                this._languageCountry = this._language.countryInfos.find(countryInfo => countryInfo.countryCode2 === languageCountryCookieObj.countryCode2);
            }
        }
        if (!this._languageCountry) { // set default LanguageCountry
            this._languageCountry = this.defaultLanguageCountry;
        }
        this.setTranslations(); // true?
    }

    private get defaultLanguageCountry(): CountryInfo {
        if (this._language) {
            if (this._language.countryInfos && this._language.countryInfos.length > 0) {
                return this._language.countryInfos[0];
            } else {
                console.error('Empty countryInfos for language "' + this._language.isoLanguage + '" ...');
                console.error(this._language);
            }
        }
        return null
    }

    private resolveSelectedCountry() {
        const countryCodeFromUrl: string = this.onLoadQueryParams['countryCode'];
        if (countryCodeFromUrl) { // CountryCode from URL
            this._selectedCountry = this._countries.countryList.find(country => country.value === countryCodeFromUrl);
        } else { // CountryCode from Cookie
            const selectedCountryCookie = this.cookies.get('sc');
            if (selectedCountryCookie && selectedCountryCookie !== 'undefined') {
                const selectedCountryCookieObj = <CountryInfoWithCode>JSON.parse(selectedCountryCookie);
                this._selectedCountry = this._countries.countryList.find(country => country.value === selectedCountryCookieObj.countryCode2);
            }
        }
    }

    public resolveUserCountry() {
        this.userCountryFromUrl = (!Utils.isJsApi() ? this.onLoadQueryParams['userCountry'] : window.gspl.params.userCountry);
        const userCountryWithCookie = this.cookies.get('uc');

        if (this.userCountryFromUrl || window.gspl.params.userCountry) {
            this._userCountry = this._countries.countryList.find(country => country.value === this.userCountryFromUrl);
        } else if (userCountryWithCookie && userCountryWithCookie !== 'undefined') {
            this._userCountry = this._countries.countryList.find(country => country.value === userCountryWithCookie);
        } else {
            if (!this.countryPopulatedByDetectCountry) {
                this.backend.detectCountry().then(result => {
                    if (result.country !== '00') {
                        const country = this.countries.countryList.find(country => country.value === result.country);
                        if (!this._userCountry) {
                            this._userCountry = country;
                            if(this.isCookiesConsentGiven() && !this.isFunctionalCookiesDisabled()){
                                this.setCookie('uc', (this._userCountry.value));
                            }
                            this.isCountryNameROW = this.appConfig.countryNameROWTranslation.find(country => country === (this._userCountry ? this._userCountry.value : '')) ? true : false;
                        }
                    }
                });
            } else {
                const userCountryWithoutCookie = (this.selectedCountry && this.selectedCountry.value) ? this.selectedCountry.value : null;
                this._userCountry = this._countries.countryList.find(country => country.value === userCountryWithoutCookie);  
            }
        }
        if (this._userCountry) {//setcookie is given to set the uc for else condition
            if(this.isCookiesConsentGiven() && !this.isFunctionalCookiesDisabled()){
                this.setCookie('uc', (this._userCountry.value));
            }
        }
        this.isCountryNameROW = this.appConfig.countryNameROWTranslation.find(country => country === (this._userCountry ? this._userCountry.value : '')) ? true : false; 
    }

    public isSelectCountryFreezed(){
        const freeze: string = this.onLoadQueryParams ? this.onLoadQueryParams['freeze'] : this.jsApiPageAttributes ;
        let freezeArray: string[] = [];
        if (freeze) {
            freezeArray = freeze.split(',');
            if(freezeArray){
                this.isCountryFreezed = freezeArray.includes('selectCountry') ? true : false;
            }else{
                this.isCountryFreezed = false;
            }
        } 
        return this.isCountryFreezed;
    }

    /*private resolveUom() {
        const uomFromUrl: string = this.onLoadQueryParams['resultUom'] || this.onLoadQueryParams['u'] || this.onLoadQueryParams['U'];
        if (uomFromUrl) { // Uom from URL
            this._uom = this.uoms.find(uom => uom.code === uomFromUrl);
        } else { // Uom from Cookie
            const uomCookie = this._cookiesAllowed ? this.cookies.get('uom') : null;
            if (uomCookie && uomCookie !== 'undefined') {
                const uomCookieCookieObj = <Uom>JSON.parse(uomCookie);
                this._uom = this.uoms.find(uom => uom.code === uomCookieCookieObj.code);
            } else { ////RTC - 567851
                if(this.selectedCountry && 
                    this.selectedCountry.unitOfLength && 
                    this.selectedCountry.unitOfLength.toUpperCase() === 'MILES'){
                    this._uom = this.milesUom;
                }
            }
        }
        
        if (!this._uom) { // set default Uom
            this._uom = this.kilometresUom;
        }
    }*/


    private resolveUom(detectCountry: boolean = false) {
        const uomFromUrl: string = this.onLoadQueryParams['resultUom'] || this.onLoadQueryParams['u'] || this.onLoadQueryParams['U'];
        const uomCookie = this.cookies.get('uom');
        const country = this.selectedCountry;
        if (!this.pageReloaded && uomFromUrl) { // Uom from URL
            this._uom = this.uoms.find(uom => uom.code === uomFromUrl);
        } else if (uomCookie && uomCookie !== 'undefined') { // Uom from cookie
            const uomCookieCookieObj = <Uom>JSON.parse(uomCookie);
            this._uom = this.uoms.find(uom => uom.code === uomCookieCookieObj.code);
        } else if (country) { // Uom from selected Country
            if (country.unitOfLength &&
                country.unitOfLength.toUpperCase() === 'MILES') {
                this._uom = this.milesUom;
            } else {
                this._uom = this.kilometresUom;
            }
        }

        if (!this._uom) { // set default Uom
            this._uom = this.milesUom;
        }

        if (this.pageReloaded) {
            this.pageReloaded = false;
        }
    }

    public resolveUomOnDetectCountry() {
        this.resolveUom(true);
    }

    public resolveUomOnCountrySelect() {//678061
        this.resolveUom(false);
    }

    private resolveLightVersion() {
        const lightFromUrl: string = this.onLoadQueryParams['light'];
        if (lightFromUrl) { // light from URL
            if (lightFromUrl.toLowerCase() === 'y') {
                this._lightVersion = true;
            } else if (lightFromUrl.toLowerCase() === 'n') {
                this._lightVersion = false;
            }
        } else { // light from Cookie
            const lightCookie = this.cookies.get('lv');
            if (lightCookie && lightCookie !== 'undefined') {
                if (lightCookie === 'true') {
                    this._lightVersion = true;
                } else if (lightCookie === 'false') {
                    this._lightVersion = false;
                }
            }
        }
    }

    private setTranslations(reloadMap = false) {

        let codeToUse = this._language.isoLanguage;

        /*if (codeToUse === 'tw') {
            codeToUse = 'zh';
        }*/ //RTC 743787

        let reload = null;

        if (reloadMap && window.location.href.includes('results')) {
            // bingmaps are dumb, no way of updating locale without having to do a full page reload like in the 90's
            let newLoc = window.location.href.replace(/language=[a-zA-Z\-\$]*/, 'language=' + codeToUse)
                .replace(/L=[a-zA-Z\-\$]*/, 'L=' + codeToUse).replace(/l=[a-zA-Z\-\$]*/, 'l=' + codeToUse)
                .replace(/lc=[a-zA-Z\-\$]*/, 'lc=' + this._languageCountry.countryCode2)
                .replace(/resultUom=[a-zA-Z\-\$]*/, 'resultUom=' + this._uom.code);

            if (!newLoc.includes('language=') && !newLoc.includes('L=') && !newLoc.includes('l=')) {
                newLoc += '&language=' + codeToUse;
            }

            if (!newLoc.includes('lc=')) {
                newLoc += '&lc=' + this._languageCountry.countryCode2;
            }

            if (!newLoc.includes('resultUom=')) {
                newLoc += '&resultUom=' + this._uom.code;
            }

            reload = newLoc;
        }

        if (codeToUse.length === 2) {
            codeToUse = codeToUse + '-' + this._languageCountry.countryCode2;
        }
        codeToUse = codeToUse + '$' + this._language.languageScript;
        this.codeToUseDirectionsPrint = codeToUse;
        if (reload) {
            window.location.href = reload;
        } else {
            this.ngZone.run(() => {
                this.translateService.use(codeToUse);
            });
            this._languageResolver();
        }

    }

    private translateCapabilities(sourceCapabilities: Array<Capability>, targetCapabilities: Array<Capability>, code0Key: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if(sourceCapabilities) {
                Promise.all([this.translateService.get(code0Key).toPromise()].concat(sourceCapabilities.map(opt => this.translateService.get(opt.labelKey).toPromise()))).then(translations => {
                    const promises: Array<Promise<void>> = [];
                    translations.forEach((val, i) => {
                        if (i === 0) {
                            targetCapabilities.push({ code: '0', labelKey: code0Key, label: '--- '.concat(val).concat(' ---') });
                        } else {
                            const capability: Capability = Object.assign({}, sourceCapabilities[i - 1])
                            capability.label = val;
                            targetCapabilities.push(capability);
                            if (sourceCapabilities[i - 1].downstreams) {
                                capability.downstreams = [];
                                promises.push(this.translateCapabilities(sourceCapabilities[i - 1].downstreams, targetCapabilities[i].downstreams, 'spl.search.choose_handling_option').then().catch(error => reject()));
                            }
                        }
                    });
                    Promise.all(promises).then(() => resolve()).catch(error => reject());
                }).catch(error => reject());
            } else {
                resolve();
            }
        });
    }


    handleCustomCapabilitiesOnCountryChange(country: Country) {
        return new Promise<void>((resolve, reject) => {
            this.collectOptionsCustom = [];
            this.paymentOptionsCustom = [];
            /*country.capabilityCustom = {
                "payments": [{
                    "code": "81",
                    "labelKey": "spl.search.capability.81",
                    "downstreams": [{
                        "code": "73",
                        "labelKey": "spl.search.capability.73"
                    }]
                }, {
                    "code": "82",
                    "labelKey": "spl.search.capability.82",
                    "downstreams": [{
                        "code": "74",
                        "labelKey": "spl.search.capability.74"
                    }, {
                        "code": "75,76",
                        "labelKey": "spl.search.capability.7576"
                    }]
                }],
                "collects": [{
                    "code": "78,79",
                    "labelKey": "spl.search.sl.collect_shipment"
                }, {
                    "code": "86,87",
                    "labelKey": "spl.search.sl.send_shipment"
                }]
            };*/

            let paymentCode0Key = 'spl.details.rs.drop_off';
            let collectCode0Key = 'spl.details.rs.collection';
            if (window.gspl.params.isLabelFree) {
                paymentCode0Key = 'spl.search.choose_payment_option';
                collectCode0Key = 'spl.search.choose_collection_option';
            }
            /*if (country.capabilityCustom &&
                country.capabilityCustom.payments &&
                country.capabilityCustom.collects) {*/
            Promise.all(
                [this.translateCapabilities(country.capabilityCustom.payments, this.paymentOptionsCustom, paymentCode0Key),
                this.translateCapabilities(country.capabilityCustom.collects, this.collectOptionsCustom, collectCode0Key)]
            ).then(() => resolve()).catch(error => reject());
            /*}*/
        });
    }

    getTranslatedValue(key: string) {
        return this.translateService.get(key);
    }

    notifySearchResultLoaded() {
        this.searchResultLoaded.emit();
    }

    notifyServicePointsFilterTriggered(servicePoints: Array<ServicePoint>) {
        this.servicePointsFilterTriggered.emit(servicePoints);
    }

    notifyQrCodePromotionChanged() {
        this.qrCodePromotionChanged.emit();
    }

    notifyServicePointSelected(selectedServicePoint: ServicePoint) {
        this.selectedServicePoint = selectedServicePoint;
        this.servicePointSelected.emit(selectedServicePoint);
    }

    notifyMapMovedOrZoomed(status: boolean) {
        this.mapMovedOrZoomed.emit(status);
    }

    notifySearchedResultList() {
        this.resultListSearched.emit();
    }

    notifySvpPromotionClosed() {
        this.svpPromotionClosed.emit();
    }

    notifyPromotionBannerClosed() {
        this.promotionBannerClosed.emit();
    }

    notifyShareButtonDisabled(data: any) {
        this.shareButtonDisabled.emit(data);
    }

    getParams(params: Params): Params {
        const encodedValue: string = params['data'];
        if (encodedValue) {
            const decodedValue = EncodeDecode.decode(encodedValue);
            const jsonValue = this.stringToJson(decodedValue);
            if (jsonValue) {
                return jsonValue;
            } else {
                //return this.queryString.parse(decodeValue);
                const params: Params = {};
                //decodedValue.split('?').pop().split('&').forEach(pairStr => {
                decodedValue.split('&').forEach(pairStr => {
                    const pair = pairStr.split('=');
                    params[pair[0]] = pair[1];
                });
                return params;
            }
        } else {
            return this.handleDefaultQrCode(params);
        }
    }

    handleDefaultQrCode(params: Params): Params {
        if (this.isResultPage() && !Utils.isJsApi() && window.gspl.params.isLabelFree) {
            var paymentsArr: any[] = this.appConfig.capabilities.payments;
            var paymentsWithQRHandling: any[] = [];
            var paymentsWithNonQRHandling: any[] = [];
            var handlings = arraytoCommaSeperated(this.appConfig.capabilities.payments.map((capab) => { return capab.downstreams.map((handling) => { return handling.code }) }));
            var payments = arraytoCommaSeperated(this.appConfig.capabilities.payments.map((capab) => { return capab.code }));
            var collects = arraytoCommaSeperated(this.appConfig.capabilities.collects.map((capab) => { return capab.code }));
            var ppcCodes = params.capability;
            var country = undefined;
            if (this.countries && this.countries.countryList && this.countries.countryList.length > 1) {
                this.countries.countryList.forEach(element => {
                    if (params && params.countryCode && element.value.toUpperCase() === params.countryCode.toUpperCase()) {
                        country = element;
                    }
                });
            }

            if (country && country.capabilityCustom && country.capabilityCustom.payments && country.capabilityCustom.collects) {
                paymentsArr = country.capabilityCustom.payments;
                payments = arraytoCommaSeperated(country.capabilityCustom.payments.map((capab) => { return capab.code }));
                handlings = arraytoCommaSeperated(country.capabilityCustom.payments.map((capab) => { return capab.downstreams.map((handling) => { return handling.code }) }));
                collects = arraytoCommaSeperated(country.capabilityCustom.collects.map((capab) => { return capab.code }));
            }

            paymentsArr.forEach(payment => {
                var status: boolean = false;
                payment.downstreams.forEach(handling => {
                    if (this.appConfig.grefQRCodePPCCodes && this.appConfig.grefQRCodePPCCodes.includes(handling.code)) {
                        status = true;
                        paymentsWithQRHandling.push(payment);
                    }
                });
                if (!status) {
                    paymentsWithNonQRHandling.push(payment)
                }
            });

            var qrhandlingCodes: string = "";
            var otherhandlingCodes: string = "";
            var paymentCollectCodes: string = "";

            if (ppcCodes) {
                ppcCodes.split(",").forEach(code => {
                    if (payments.includes(code) || collects.includes(code)) {
                        paymentCollectCodes = paymentCollectCodes + code + ","
                    } else {
                        if (handlings.includes(code) && this.appConfig.grefQRCodePPCCodes.includes(code)) {
                            qrhandlingCodes = qrhandlingCodes + code + ",";
                        } else {
                            otherhandlingCodes = otherhandlingCodes + code + ",";
                        }
                    }
                });
            }
            if (qrhandlingCodes.endsWith(",")) {
                qrhandlingCodes = qrhandlingCodes.substring(0, qrhandlingCodes.length - 1);
            }
            if (otherhandlingCodes.endsWith(",")) {
                otherhandlingCodes = otherhandlingCodes.substring(0, otherhandlingCodes.length - 1);
            }
            if (paymentCollectCodes.endsWith(",")) {
                paymentCollectCodes = paymentCollectCodes.substring(0, paymentCollectCodes.length - 1);
            }


            //console.log('filterSelected',this.isFilterSelected);
            //if(params && params.defaultQrCode && params.defaultQrCode.toLowerCase() != 'n'
            //    && this.appConfig.grefQRCodePPCCodes){
            this.checkOnlyQRPPCExistInParams(params, payments, handlings, collects, paymentsWithQRHandling, paymentsWithNonQRHandling);
            //}



            if (params && params.defaultQrCode
                && params.defaultQrCode.toLowerCase() == 'y'
                && this.appConfig.grefQRCodePPCCodes
                //&& !this.defaultQrCodeParameter 
                && this.checkQRPPCExistInParams(params, collects, paymentsWithQRHandling, paymentsWithNonQRHandling)) {
                this.enableQRCodeByDefault = true;
                this.ppcCodesInUrl = params.capability;
                const paramObj: Params = Object.assign({}, params);
                paramObj['capability'] = paymentCollectCodes + "," + qrhandlingCodes;
                return paramObj;
            } else {
                this.ppcCodesInUrl = params.capability;
                return params;
            }
        }
        return params;
    }

    handleDefaultQrCodeForJsApi(paramsRec: Params) {
        if ((this.isResultPage() || this.isResultReachedFromHomeLanding) && Utils.isJsApi() && window.gspl.params.isLabelFree) {
            var paymentsArr: any[] = this.appConfig.capabilities.payments;
            var paymentsWithQRHandling: any[] = [];
            var paymentsWithNonQRHandling: any[] = [];
            var handlings = arraytoCommaSeperated(this.appConfig.capabilities.payments.map((capab) => { return capab.downstreams.map((handling) => { return handling.code }) }));
            var payments = arraytoCommaSeperated(this.appConfig.capabilities.payments.map((capab) => { return capab.code }));
            var collects = arraytoCommaSeperated(this.appConfig.capabilities.collects.map((capab) => { return capab.code }));
            var country = undefined;
            var params: Params = paramsRec;
            if (!params.countryCode && !params.capability) {
                params = this.jsApiPageParam;
            }
            if (this.countries && this.countries.countryList && this.countries.countryList.length > 1) {
                this.countries.countryList.forEach(element => {
                    if (params && params.countryCode && element.value.toUpperCase() === params.countryCode.toUpperCase()) {
                        country = element;
                    }
                });
            }

            if (country && country.capabilityCustom && country.capabilityCustom.payments && country.capabilityCustom.collects) {
                paymentsArr = country.capabilityCustom.payments;
                payments = arraytoCommaSeperated(country.capabilityCustom.payments.map((capab) => { return capab.code }));
                handlings = arraytoCommaSeperated(country.capabilityCustom.payments.map((capab) => { return capab.downstreams.map((handling) => { return handling.code }) }));
                collects = arraytoCommaSeperated(country.capabilityCustom.collects.map((capab) => { return capab.code }));
            }

            paymentsArr.forEach(payment => {
                var status: boolean = false;
                payment.downstreams.forEach(handling => {
                    if (this.appConfig.grefQRCodePPCCodes && this.appConfig.grefQRCodePPCCodes.includes(handling.code)) {
                        status = true;
                        paymentsWithQRHandling.push(payment);
                    }
                });
                if (!status) {
                    paymentsWithNonQRHandling.push(payment)
                }
            });
            this.checkOnlyQRPPCExistInParams(params, payments, handlings, collects, paymentsWithQRHandling, paymentsWithNonQRHandling);

            if (this.checkQRPPCExistInParams(params, collects, paymentsWithQRHandling, paymentsWithNonQRHandling) && params && params.defaultQrCode
                && params.defaultQrCode.toLowerCase() == 'y'
                && this.appConfig.grefQRCodePPCCodes) {
                this.enableQRCodeByDefault = true;
            }
        }
    }

    public checkQRPPCExistInParams(params: Params, collect: string, paymentsWithQRHandling: any[], paymentsWithNonQRHandling: any[]): Boolean {
        var paymentsWithQRHandlingCodesOnly = arraytoCommaSeperated(paymentsWithQRHandling.map(element => { return element.code }));
        var paymentsWithNonQRHandlingCodesOnly = arraytoCommaSeperated(paymentsWithNonQRHandling.map(element => { return element.code }));
        var qrPpcInUrlStatus: boolean = false;
        var qrCollectPaymentInUrlStatus: boolean = true;
        var ppcCodesInUrl: string[] = [];
        if (params && params.capability) {
            ppcCodesInUrl = params.capability.split(",");
            ppcCodesInUrl.forEach(element => {
                if (this.appConfig.grefQRCodePPCCodes.includes(element)) {
                    qrPpcInUrlStatus = true;
                }

                if (paymentsWithNonQRHandlingCodesOnly.includes(element) ||
                    collect.includes(element)) {
                    if (collect.includes(element)) {
                        qrCollectPaymentInUrlStatus = false;
                    }
                    if (paymentsWithNonQRHandlingCodesOnly.includes(element)) {
                        qrCollectPaymentInUrlStatus = false;
                    }
                }
            }
            );
        }
        return qrPpcInUrlStatus && qrCollectPaymentInUrlStatus;
    }

    public checkOnlyQRPPCExistInParams(params: Params, payments: string, handling: string, collect: string, paymentsWithQRHandling: any[], paymentsWithNonQRHandling: any[]) {
        var qrHandlingInUrlStatus: boolean = false;//764701
        var qrPaymentInUrlStatus: boolean = false;
        var qrCollectInUrlStatus: boolean = false;
        var nonQrHandlingInUrlStatus: boolean = false;
        var ppcCodesInUrl: string[] = [];
        var paymentsWithQRHandlingCodesOnly = arraytoCommaSeperated(paymentsWithQRHandling.map(element => { return element.code }));
        var paymentsWithNonQRHandlingCodesOnly = arraytoCommaSeperated(paymentsWithNonQRHandling.map(element => { return element.code }));
        if (params && params.capability) {
            ppcCodesInUrl = params.capability.split(",");
            ppcCodesInUrl.forEach(element => {
                if (paymentsWithQRHandlingCodesOnly.includes(element) ||
                    paymentsWithNonQRHandlingCodesOnly.includes(element) ||
                    collect.includes(element)) {
                    if (collect.includes(element)) {
                        qrCollectInUrlStatus = true;
                    }
                    if (paymentsWithQRHandlingCodesOnly.includes(element)) {
                        qrPaymentInUrlStatus = true;
                    }
                    if (paymentsWithNonQRHandlingCodesOnly.includes(element)) {
                        nonQrHandlingInUrlStatus = true;
                    }
                } else if (this.appConfig.grefQRCodePPCCodes.includes(element)) {
                    qrHandlingInUrlStatus = true;
                } else {
                    nonQrHandlingInUrlStatus = true;
                }

            }

            );
        }
        if (qrPaymentInUrlStatus && qrHandlingInUrlStatus && !nonQrHandlingInUrlStatus) {//764701
            this.qrPPCCodesOnlyInUrl = true;
        } else {
            this.qrPPCCodesOnlyInUrl = false;
        }

    }

    private stringToJson(value: string): any {
        try {
            return JSON.parse(value);
        } catch (error) {
            return null;
        }
    }

    setSearchResultJsApi(servicePoints: any, isLoadServicePoints: boolean = false) {
        if (window.gspl.wsbSearch) {
            if (!this.searchResult) {
                // todo call WSB with given parameters
            }
        } else {
            if (servicePoints) {
                if (!servicePoints.mapType) {
                    this.backend.searchServicePointsUiProperties(servicePoints, null, this)
                        .then(searchResult => {
                            this.searchResult = searchResult;
                            this.notifySearchResultLoaded();
                        })
                        .catch(error => {
                            console.error('Failed searchServicePointsUiProperties. ', error);
                        });
                } else {
                    this.searchResult = servicePoints;
                    if (isLoadServicePoints) {
                        this.notifySearchResultLoaded();
                    }
                }
            }
        }
    }

    initializeParamsJsApi(params: Params) {
        if (params) {
            let isSetTranslations = false;
            let languageParam = params['language'];
            let iso2LanguageParam = params['iso2Language'];
            let languageCountryCodeParam = params['languageCountryCode'];
            let languageScriptParam = params['languageScript'];
            let language = null;
            if (languageParam) {
                languageParam = languageParam.toLowerCase();
                language = this._languages[languageParam];
            } else if (iso2LanguageParam) {
                iso2LanguageParam = iso2LanguageParam.toLowerCase();
                let array = null;
                if (languageCountryCodeParam && languageScriptParam) {
                    languageCountryCodeParam = languageCountryCodeParam.toLowerCase();
                    languageScriptParam = languageScriptParam.toLowerCase();
                    array = Object.entries<any>(this.languages).find(([key, value]) =>
                    ((value.iso2Language ? value.iso2Language.toLowerCase() : value.iso2Language) === iso2LanguageParam
                        && (value.countryCode ? value.countryCode.toLowerCase() : value.countryCode) === languageCountryCodeParam
                        && (value.languageScript ? value.languageScript.toLowerCase() : value.languageScript) === languageScriptParam));
                } else if (languageCountryCodeParam) {
                    languageCountryCodeParam = languageCountryCodeParam.toLowerCase();
                    array = Object.entries<any>(this.languages).find(([key, value]) =>
                    ((value.iso2Language ? value.iso2Language.toLowerCase() : value.iso2Language) === iso2LanguageParam
                        && (value.countryCode ? value.countryCode.toLowerCase() : value.countryCode) === languageCountryCodeParam));
                } else if (languageScriptParam) {
                    languageScriptParam = languageScriptParam.toLowerCase();
                    array = Object.entries<any>(this.languages).find(([key, value]) =>
                    ((value.iso2Language ? value.iso2Language.toLowerCase() : value.iso2Language) === iso2LanguageParam
                        && (value.languageScript ? value.languageScript.toLowerCase() : value.languageScript) === languageScriptParam));
                } else {
                    array = Object.entries<any>(this.languages).find(([key, value]) =>
                        ((value.iso2Language ? value.iso2Language.toLowerCase() : value.iso2Language) === iso2LanguageParam));
                }
                if (!array) {
                    array = Object.entries<any>(this.languages).find(([key, value]) =>
                        ((value.iso2Language ? value.iso2Language.toLowerCase() : value.iso2Language) === iso2LanguageParam));
                }
                if (array) {
                    language = array[1];
                }
            }
            if (language && language !== this.language.isoLanguage) {
                this.setLanguage(language, false, false, true, false);
                isSetTranslations = true;
            }

            //const languageCountryCodeParam = params['languageCountryCode'];
            if (languageCountryCodeParam) {
                const languageCountry = this._language.countryInfos.find(countryInfo => (countryInfo.countryCode2 ? countryInfo.countryCode2.toLowerCase() : countryInfo.countryCode2) === languageCountryCodeParam);
                if (languageCountry && languageCountry.countryCode2 !== this.languageCountry.countryCode2) {
                    this.setLanguageCountry(languageCountry, false, false, false);
                    isSetTranslations = true;
                }
            }

            if (isSetTranslations) {
                this.setTranslations();
            }

            const uomParam: string = params['resultUom'];
            if (uomParam) {
                const uom = this.uoms.find(uom => uom.code === uomParam);
                if (uom.code !== this.uom.code) {
                    this.setUom(uom);
                }

            }

            const countryCode = params['countryCode'];
            if (countryCode) {
                if (this.selectedCountry && this.selectedCountry.value === countryCode) {
                    // do not set
                } else {
                    this.selectedCountry = this._countries.countryList.find(country => country.value === countryCode);
                }
            }

            const userCountry = params['userCountry'];
            if (this.userCountry && this.userCountry.value === userCountry) {
                // do not set
            } else {
                this.userCountry = this._countries.countryList.find(country => country.value === userCountry);
            }
        }
    }


    notifyNavigationLinkClicked(url: string) {
        this.navigationLinkClicked.emit(url);
    }


    /*BR11 - UXRedesign S1*/
    isResultPage() {
        if (window.location.href.includes('results')) {
            return true;
        }
        return false;
    }

    isAdvSearchPage() {
        if (window.location.href.includes('advanced-search')) {
            return true;
        }
        return false;
    }

    /*isMyDHLPlusOrDHLE() {
        if (this.backend.clientAppCode && (this.backend.clientAppCode.toLowerCase() === 'mydhlplus' || this.backend.clientAppCode.toLowerCase() === 'dhle')) {
            return true;
        }
        return false;
    }*/
    //3.1 - BR1 

    /*isMyDHLPlusOrOdd() { // this has to be replaced with the permanent fix of reducing the cookie size by having only the necessary values.
        //console.log("Utils.isJsApi()",Utils.isJsApi());
        //console.log("this.backend.clientAppCode",this.backend.clientAppCode);
        if (Utils.isJsApi()) {
            return true;
        }
        return false;
    }*/


    isDisplayCookiesbanner(route: ActivatedRoute) {
        route.queryParams.subscribe(qp => {
            var currentUrl = window.location.href;
            if (currentUrl.indexOf("?") < 0) {
                this.clientAppCode = "gspl";
                //this.cookieDisplayStatus = true;  //694365
            } else if (qp.clientAppCode && (qp.clientAppCode === 'gspl' || qp.clientAppCode === '')) {
                this.clientAppCode = "gspl";
                //this.cookieDisplayStatus = true; //694365
            } else if (qp.clientAppCode && (qp.clientAppCode != 'gspl' && qp.clientAppCode != '')) {
                this.clientAppCode = qp.clientAppCode;
                //this.cookieDisplayStatus = false; //694365
            } else {
                this.clientAppCode = "";
                //this.cookieDisplayStatus = false; //694365
            }
        });
    }

    validateForMandatoryParameters(route: ActivatedRoute) {
        route.queryParams.subscribe(qp => {
            var currentUrl = window.location.href;
            if (currentUrl.indexOf("error") < 0) {
                if (currentUrl.indexOf("?") < 0) {
                    this.clientAppCode = "gspl";
                    this.isParameterizedExtClientCallValidationFailed = false;
                } else if (qp.clientAppCode && (qp.clientAppCode === 'gspl' || qp.clientAppCode === '')) {
                    this.clientAppCode = "gspl";
                    this.isParameterizedExtClientCallValidationFailed = false;
                } else if (qp.clientAppCode && (qp.clientAppCode != 'gspl' && qp.clientAppCode != '')) {
                    this.clientAppCode = qp.clientAppCode.toLowerCase();
                    this.isParameterizedExtClientCallValidationFailed = true;
                    var data = JSON.stringify(this.appConfig.clientIdKeys).replace("{", '').replace("}", '');
                    if (data) {
                        data.split(",").forEach((element) => {
                            var tableValue: String = atob(element.split(":")[0].split('"').join(''));
                            tableValue = tableValue ? tableValue.toLowerCase() : '';
                            if (this.clientAppCode === tableValue) {
                                this.isParameterizedExtClientCallValidationFailed = false;
                                if (!this.backend.wsbKey) {
                                    this.backend.wsbKey = element.split(":")[1].split('"').join('');
                                }
                            }
                        });
                    }
                } else if ((qp.wsbKey && (qp.wsbKey != '')) || (qp.key && (qp.key != ''))) {
                    //this.clientAppCode = this.appConfig.wsbKey; // This needs to be revisited while making the change again. Important..
                    if (this.appConfig.jsapiv3Usegsplwsbkey) {
                        this.backend.clientAppCode = "gspl";
                        this.clientAppCode = "gspl";
                    } else {
                        if (!this.backend.clientAppCode && this.backend.wsbKey) {
                            var data = JSON.stringify(this.appConfig.clientIdKeys).replace("{", '').replace("}", '');
                            if (data) {
                                data.split(",").forEach((element) => {
                                    let elementValue = element.split(":")[1].split('"').join('');
                                    if (elementValue === this.backend.wsbKey) {
                                        this.backend.clientAppCode = atob(element.split(":")[0].split('"').join(''));
                                        this.clientAppCode = this.backend.clientAppCode;
                                    }
                                });
                            }
                        }
                    }
                    this.isParameterizedExtClientCallValidationFailed = false;
                } else {
                    if (this.appConfig.clientAppCodeValidation) {
                        this.isParameterizedExtClientCallValidationFailed = this.appConfig.clientAppCodeValidation;
                    } else {
                        this.backend.clientAppCode = "gspl";
                        this.clientAppCode = "gspl";
                        this.isParameterizedExtClientCallValidationFailed = this.appConfig.clientAppCodeValidation;
                    }

                    // should be true but Amazon incident for parameterized url
                }
            }
        });
    }

    getMessageDetails(countryId: string, languageId: string, clientAppCode: string, messageType: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.backend.getMessageDetails(countryId, languageId, clientAppCode, messageType).then(data => {
                this.messageDetails = data;
                resolve();
            });
        });
    }

    getMessageDetailsByPriorityForExpressMessage(ppcCombination: string[]) {
        if (this.messageDetails) {
            this.expressMessage = undefined;
            this.expressMessageWithLink = undefined;
            this.messageDetails.messageDetails.forEach(element => {
                ppcCombination.forEach(ppcComb => {
                    let ppcsTrimmed: string[] = element.ppcCombination.split(":").map(elementPpc => elementPpc = elementPpc.trim());
                    if (ppcsTrimmed.includes(ppcComb.trim())) {
                        if (this.expressMessage && this.expressMessage.priority > element.priority && this.appConfig.messageTypes[0] && element.messageType.toLowerCase() == this.appConfig.messageTypes[0].toLowerCase()) {
                            this.expressMessage = element;
                        } if (this.expressMessageWithLink && this.expressMessageWithLink.priority > element.priority && this.appConfig.messageTypes[1] && element.messageType.toLowerCase() == this.appConfig.messageTypes[1].toLowerCase()) {
                            this.expressMessageWithLink = element;
                        } else if (!this.expressMessage && this.appConfig.messageTypes[0] && element.messageType.toLowerCase() == this.appConfig.messageTypes[0].toLowerCase()) {
                            this.expressMessage = element; // for the first match message
                        } else if (!this.expressMessageWithLink && this.appConfig.messageTypes[1] && element.messageType.toLowerCase() == this.appConfig.messageTypes[1].toLowerCase()) {
                            this.expressMessageWithLink = element; // for message with link
                        } else {
                            // do nothing, it means, priority is already less than the new match
                        }
                    }
                });
                //});                
            });
        }
    }

    getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length == 2)
            return parts.pop().split(";").shift();
    }

    /**
     * Set page Attributes
     */
    setJsApiPageAttributes(windowGsplParams?) {
        if (Utils.isJsApi()) {
            if (windowGsplParams) {
                window.gspl.params = { ...window.gspl.params, ...windowGsplParams }
            }
            this.jsApiPageAttributes = window.gspl.params;
        }
    }

    /**
     * Remove page attributes
     * @param attributes {PageAttributes} 
     */
    removeJsApiPageAttributes() {
        if (Utils.isJsApi()) {
            this.jsApiPageAttributes = {};
        }
    }


    isCookiesConsentGiven(): boolean {
        if (typeof window.OnetrustActiveGroups != 'undefined' && window.OnetrustActiveGroups.includes(this.funcCookieCateoryId) ) { // && !this.isMyDHLPlusOrOdd() 
            return true;
        } else {
            //console.error("OnetrustActiveGroups is undefined");
            return false;
        }
    }

    checkQRPromotionAlertMessage(hasQrCode: boolean, hasSpPromotion: boolean) {
        this.isShowAlertMsg = false;
        if(this.prevpromotionFlag && this.prevQrFlag && !hasQrCode && !hasSpPromotion ) {
            this.isShowAlertMsg = true;
            if(this._searchResult && this._searchResult.promotion && this._searchResult.promotion.text) {
                this.alert = {
                    image: 'striked-out-qrcode.png',
                    message1: 'spl.result.banner.no_qr',
                    message2: 'spl.result.banner.qr_reset'
                }
            } else {
                this.alert = {
                    image: 'warning.png',
                    message1: 'spl.result.banner.no_promotion_qr',
                    message2: 'spl.result.banner.promotion_qr_reset'
                }
                
            }
            
        } 
        else if(this.prevQrFlag && !hasQrCode && (!this.qrPPCCodesOnlyInUrl && this._searchResult.servicePoints)) {
            this.isShowAlertMsg = true;
            this.alert = {
                image: 'striked-out-qrcode.png',
                message1: 'spl.result.banner.no_qr',
                message2: 'spl.result.banner.qr_reset'
            }
        } 
        else if(this.prevpromotionFlag && !hasSpPromotion) {
            this.isShowAlertMsg = true;
            if(this._searchResult && this._searchResult.promotion && this._searchResult.promotion.text) {
                this.isShowAlertMsg = false;
                //dont show the alert message if country level promotion already exists.
            } else {
                this.alert = {
                    image: 'striked-out-promotion.png',
                    message1: 'spl.result.banner.no_promotion',
                    message2: 'spl.result.banner.promotion_reset'
                }
            }
            
        }
    }

    checkShowMapConsent(mapType: string) {
        var mapConsentCategoryId = this.appConfig.gsplCookieCategory.filter(val => val.indexOf('googlemap') > -1)[0].split(":")[1];
        if (mapType && mapType.toLowerCase() == 'bing') {
            mapConsentCategoryId = this.appConfig.gsplCookieCategory.filter(val => val.indexOf('bingmap') > -1)[0].split(":")[1];
        }
        if (typeof window.OnetrustActiveGroups !== 'undefined' && window.OnetrustActiveGroups.indexOf(mapConsentCategoryId) > -1) {
            this.isMapConsentGiven = true;
        } else {
            this.isMapConsentGiven = false;
        }
        return this.isMapConsentGiven;
    }

    getCookieLinkDisplayStatusForJsApiV3(){
        try{
            if(typeof window.OnetrustActiveGroups !== 'undefined' && Utils.isJsApi()) {
                return false;
            }
        } catch(error){
            // error is expected here
            return false;
        }
        return true;
    }

    /*isBannerSuppresedClient(queryParams:Params){
        try{
            if(this.appConfig.cookiesBannerRestrictedClients && this.appConfig.cookiesBannerRestrictedClients.includes(this.backend.clientAppCode) 
            && (queryParams && queryParams.optanon && (queryParams.optanon.toLowerCase() == 'y' || queryParams.optanon.toLowerCase() == 'yes'))) {
                return true;
            }
        } catch(error){
            return false;
        }
        return false;
    }*/

    isOneTrustLoaded(){
        return Utils.isOneTrustLoaded();
    }

    /*suppressCookiesPanel(){
        if(!this.isBannerSuppresedClient()) {
            if(document.getElementById("onetrust-banner-sdk") && document.getElementsByClassName("onetrust-pc-dark-filter")[0]){
                var ele:any = document.getElementsByClassName("onetrust-pc-dark-filter")[0];
                ele.style="display: block";
                const compStyle = window.getComputedStyle(ele);
                document.getElementById("onetrust-banner-sdk").style.display = 'block';
                document.getElementById("onetrust-banner-sdk").style.zIndex = compStyle.getPropertyValue('z-index');
            }
        }
    }*/
}
