<div *ngIf="_isOpen" [dir]="dir" class="textAlignRtl" [ngClass]="'col mx-2 pt-2 h-100'" [ngStyle]="{'display': 'flex', 'flex-direction': 'column'}">
    <div *ngIf="!isSearchButtonNeedToHide">
        <div *ngIf="showCountrySelect === 'true' || showAddressInput === 'true'" [ngClass]="'row results-list-customization'+ (settings?.isRTL()?' results-list-customization-rtl':'')" [ngStyle]="{'flex-wrap': 'unset'}">
            <app-country-select [style.display]="showCountrySelect === 'true' ? '' : 'none'" *canHide="'selectCountry'" class="col px-0" [ngClass]="'col-auto px-0 selectCountryCls '+(hide.yourLocation?'selectCountryHide':'selectCountryWidth')" [abbreviated]="true" [countries]="countries" [selectedCountry]="selectedCountry"
                (selected)="onCountrySelect($event)"></app-country-select>
            <div *ngIf="!hide.selectCountry" [style.display]="showCountrySelect === 'true' ? '' : 'none'" class="col-auto px-1"></div>
            <app-address-input [style.display]="showAddressInput === 'true' ? '' : 'none'" *canHide="'yourLocation'" class="col px-0" [customAutocomplete]="true" [selectedCountry]="selectedCountry" [address]="address" [abbreviated]="true"
                (events)="onAddressEvent($event)" (submit)="onSearchClick()" ></app-address-input>
            <div *ngIf="showSearchButton === 'true'" class="col-auto px-1"></div>
            <div *ngIf="showSearchButton === 'true'" class="col-auto px-0">
                <button class="search"
                    [disabled]="((isID() || (isAddressLongEnough() && capability.isCapabilitySelectedWithHide())) && advancedSearchCriteria.isLimitationValid) ? false : true"
                    [title]="!isAddressLongEnough()? (isID() ? '' : ('spl.search.insert_location' | translate)) : (capability.isCapabilitySelectedWithHide() ? '': (isID() ? '' : ('spl.search.select_actio' | translate)))"
                    (click)="onSearchClick()" aria-label="Search">
                    <img class="search-icon" [ngStyle]="{'left.px': settings?.isRTL() ? 19 : null}" [src]="settings.backendUrl + '/assets/search-icon.svg'" alt="search icon">
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="showFilter === 'true'" class="row mt-2">
        <div  tabindex="0" class="col-auto pl-0" [ngStyle]="{'padding-right': '30px'}">
            <div class="text-red-14 font-weight-bold" [ngStyle]="{'width': 'fit-content', 'cursor': 'pointer'}" (click)="filterOpened = !filterOpened">
                {{'spl.result.filter' | translate}}<i class="material-icons mat-icon">{{filterOpened ? 'expand_less' : 'chevron_right' }}</i>
            </div>
        </div>
        <div class="col px-0"></div>
        <div class="col-auto">
            <div  class="row" aria-disabled="true">
                <div *ngIf="selectedCountry.value!=null && selectedCountry.value.qrCheckCode" class="col-auto px-0 ml-auto qr-section" >
                    <input type="checkbox" class="checkbox" [formControl]="settings.acceptQrCodeFilter" (change)="onAcceptQrCodeFilterChange()" [ngStyle]="{'width': '25px', 'top': '-2px'}" aria-label="Accept QR code">
                    <span class="font-weight-bold" [style.opacity]="(settings.acceptQrCodeFilter?.disabled) ? '0.3' : 'unset'" [ngStyle]="{'font-size': '14px', 'margin-right': '10px'}">{{'spl.result.acceptqrcode' | translate}}</span>
                    <img [style.opacity]="(settings.acceptQrCodeFilter?.disabled)? '0.3' : 'unset'" [ngStyle]="{'height': '26px', 'width': '26px'}" [src]="settings.backendUrl + '/assets/qr-icon.svg'" alt="To indicate the servicePoints which has QR scanning facility enabled">
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="filterOpened" class="row" [ngStyle]="{'position': 'relative'}">
        <div class="col-auto px-0 py-3 filter-panel">
            <div class="row px-3">
                <div class="col" [ngStyle]="{'padding-top': '6px', 'flex-basis': 'auto', 'width': '50%' ,'display': 'flex', 'align-items': 'center'}">
                    <input type="checkbox" class="checkbox" [formControl]="settings.spPromotionFilter" (change)="onSpPromotionFilterChange()">
                    <span class="font-weight-bold" [style.opacity]="(servicePoints?.hasSpPromotion)? 'unset' : '0.3'" [ngStyle]="{'font-size': '14px', 'margin-right': '8px'}">{{'spl.result.promotion.title' | translate}}</span>
                </div><!--3.1 BR1  && !settings.isMyDHLPlusOrDHLE()-->
                <div class="col-auto pl-0">
                    <img [style.opacity]="(servicePoints?.hasSpPromotion) ? 'unset' : '0.3'" [ngStyle]="{'height': '32px', 'width': '32px'}" [src]="settings.backendUrl + '/assets/promotion-icon.png'">
                </div><!--3.1 BR1  && !settings.isMyDHLPlusOrDHLE()-->
            </div>
        </div>
    </div>
    <div class="modal-backdrop modal-backdrop-index" *ngIf="filterOpened" (click)="filterOpened = false"></div>
    <div class="row mt-2">
        <div  tabindex="0" class="col col-7 pl-0" [ngStyle]="{'padding-right': '25px'}" [ngClass]="filterOpened? 'adv-index-1':''"> <!-- This condition is added to fix the z-index issue in IE-->
            <div *ngIf="showAdvancedSearch === 'true'" class="text-red-14 font-weight-bold" [ngStyle]="{'width': 'fit-content', 'cursor': 'pointer'}" (click)="toggleAdvancedSearch()">
                {{!advancedOpened?('spl.search.advance' | translate):('spl.search.service_point_listing' | translate)}}<i class="material-icons mat-icon">{{advancedOpened ? 'chevron_right' : 'chevron_right' }}</i>
            </div>
        </div>
        <div class="col">
            <div class="row">
                <div class="col-auto px-0 ml-auto" [ngStyle]="{'font-family': 'DeliveryBold, sans-serif', 'font-size': '14px'}">
                    <span>{{'spl.result.found'|translate}}:&nbsp;</span>
                    <span [ngStyle]="{'font-weight': 'bold'}">{{servicePoints?.servicePoints?.length || 0}}</span>
                    <span>&nbsp;{{'spl.result.results'|translate}}</span>
                </div>
            </div>
        </div>
    </div>
    <!--class="row mt-2 pr-2 scroll4" style="overflow-y: scroll; overflow-x: hidden; flex:1"-->
    <div *ngIf="advancedOpened" class="row mt-2 pr-2 scroll4" [ngStyle]="{'overflow-y': 'auto', 'overflow-x': 'hidden', 'flex':'1'}"
    [style.height]="advancedOpened ? 'calc(100vh - 112px)' : 
        ((showCountrySelect === 'true' || showAddressInput === 'true') && showFilter === 'true' ? 'calc(100% - 112px)' : 
            (showCountrySelect === 'true' || showAddressInput === 'true' ? 'calc(100% - 78px)' : 
                (showFilter === 'true' ? 'calc(100% - 76px)' : 'calc(100% - 42px)')
            )
        )">
        <div class="col">
            <app-adv-search-criteria [ngClass]="(disabled?'row cursor-notallowed':'row')" [title]="(disabled? (!isID()? ('spl.search.insert_location' | translate) :('spl.search.service_not_required' | translate)):'')" [isaddressAnID] = "isID()" [capability]="capability" [searchCriteria]="advancedSearchCriteria" [compact]="true" [mandatoryIndicator]="mandatoryIndicator" [isCapabilitySelected]="capability.isCapabilitySelectedWithHide()" [infoIcon]="'red'" [disabled]="disabled" [disablePPC]="disablePPC"></app-adv-search-criteria>
            <div class="row my-3 mt-md-4">
                <div [ngClass]="'col-6 my-auto ' + (settings?.isRTL()?'pr-1':'pl-0') + (isclearFilterNotDisabled()?'':' filter-disabled')">
                    <a [attr.tabindex]="isclearFilterNotDisabled() ? '0' : '-1'" [ngClass]="'text-link-red font-weight-bold '+ (settings?.isRTL()?'float-right':'float-left') + (isclearFilterNotDisabled() ? '' : ' cursor-notallowed')" [title]="((isclearFilterNotDisabled() || isID()) ? '' : ('spl.search.filter_mesage' | translate))"  (click)="isclearFilterNotDisabled() ? clear() : null">{{"spl.search.btn_reset" | translate}}</a>
                </div>
                <div [ngClass]="'col-6 ' + (settings?.isRTL()?'pl-1':'pr-0')">
                    <button id="searchButton" [ngClass]="'rounded-lg ' + (settings?.isRTL()?'float-left':'float-right')" mat-button
                            [disabled]="((isID() || (isAddressLongEnough() && capability.isCapabilitySelectedWithHide())) && advancedSearchCriteria.isLimitationValid) ? false : true"
                            (click)="onSearchClick()" [title]="!isAddressLongEnough()? (isID() ? '' : ('spl.search.insert_location' | translate)) : (capability.isCapabilitySelectedWithHide() ?'': (isID() ? '' : ('spl.search.select_actio' | translate)))">
                        <span>{{"spl.search.btn_text" | translate}}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!advancedOpened" class="row mt-2 pr-2 scroll4" [ngStyle]="{'overflow-y': 'scroll', 'overflow-x': 'hidden', 'flex':'1'}"
          [style.height]="advancedOpened ? 'calc(100vh - 112px)' : 
        ((showCountrySelect === 'true' || showAddressInput === 'true') && showFilter === 'true' ? 'calc(100% - 112px)' : 
            (showCountrySelect === 'true' || showAddressInput === 'true' ? 'calc(100% - 78px)' : 
                (showFilter === 'true' ? 'calc(100% - 76px)' : 'calc(100% - 42px)')
            )
        )">
        <div tabindex="0" role="list" aria-label="Navigate to each list item represents a DHL Service Point" class="col outerResult">
            <app-result-item role="listitem" id="{{servicePoint.facilityId + i}}" class="row mb-1 shadow border rounded-lg" [ngClass]="servicePoint.isSelected ? 'result-item-selected' : 'result-item'"
                [class]="(settings?.isRTL()?' RTL':'')"
                [servicePoint]="servicePoint" [lightVersion]="settings.lightVersion"
                [lightVersionOrder]="i+1" [rtl]="settings?.isRTL()" 
                [locationButton]="locationButton"
                *ngFor="let servicePoint of servicePoints?.servicePoints; let i = index"
                (onSelected)="itemSelected(servicePoint)"
                (onSelectedLocation)="selectedLocation(servicePoint)">
            </app-result-item>
        </div>
        <div class="col-12 no-results" *ngIf="(!servicePoints?.servicePoints || servicePoints?.servicePoints.length === 0) && !loading">
            <div><img [src]="settings.backendUrl + '/assets/search-error.png'"></div>
            <div class="no-results-desc">
                <div [ngStyle]="{'padding-left': '10px'}">{{errorText | translate}}
                    <div *ngIf="settings.isMapMovedToSea">
                        {{'spl.result.error.invalid_map_loc' | translate}}
                    </div>
                </div>
                <br/>
                <div *ngIf="settings.isMapMovedToSea" [ngStyle]="{'display': 'flex','justify-content': 'center','align-items': 'center', 'padding-left': '0px'}">
                    <button id="yesButton" mat-button [ngStyle]="{'border-radius': '0.3rem', 'width': '130px'}"(click)="onYesButtonClick()">
                        <span>{{'spl.result.error.txt_btn' | translate}}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!isJsApi() && settings.selectedCountry && !settings.selectedCountry.secondLevel && advancedOpened" 
    [style.height]="((showCountrySelect === 'true' || showAddressInput === 'true') && showFilter === 'true' ? 'calc(100vh - 712px)' : 
                    (showCountrySelect === 'true' || showAddressInput === 'true' ? 'calc(100vh - 678px)' : 
                        (showFilter === 'true' ? 'calc(100vh - 678px)' : 'calc(100vh - 642px)')
                    )
                )">&nbsp;</div>
    <div *ngIf="isJsApi() && settings.selectedCountry && !settings.selectedCountry.secondLevel && advancedOpened" 
    [style.height]="((showCountrySelect === 'true' || showAddressInput === 'true') && showFilter === 'true' ? 'calc(100vh - 610px)' : 
                    (showCountrySelect === 'true' || showAddressInput === 'true' ? 'calc(100vh - 576px)' : 
                        (showFilter === 'true' ? 'calc(100vh - 576px)' : 'calc(100vh - 540px)')
                    )
                )">&nbsp;</div>
    <div class="loading-wrap" *ngIf="loading && !advancedOpened">
        <div class="ng-spinner-loader loading"></div>
    </div>
    <div *ngIf="isJsApi() && (!settings.isOneTrustLoaded() || getClientAppCode())" class="row d-none d-md-flex" [ngStyle]="{'height': '30px'}">
        <div class="col-md-12" [ngClass]="(settings?.isRTL()?'pr-0':'pl-0')">
            <a id="ot-sdk-btn" [ngClass]="'mt-2 text-link font-weight-bold '+ (settings?.isRTL()?'float-right':'float-left')" (click)="toggleConsentSettings()" class="ot-sdk-show-settings">Consent Settings</a>
        </div>
    </div> 
</div>
<div *ngIf="!isJsApi() && mobile && settings.mobileHiding" class="col-12 d-md-flex" [ngStyle]="{'height': '30px', 'background-color': '#EBEBEB'}">
    <div class="col" [ngClass]="(settings?.isRTL()?'pr-0':'pl-0')" >
        <a id="ot-sdk-btn" [ngClass]="'mt-2 font-weight-bold '+ (settings?.isRTL()?'float-right':'float-left')"  [ngStyle]="{'float': 'right', 'margin-right': settings.isRTL() ? '0px' : '-20px'}" class="ot-sdk-show-settings">Consent Settings</a>    </div>
</div>
<div *ngIf="!isJsApi() && !mobile && settings.mobileHiding" class="col-12 d-md-flex" [ngStyle]="{'height': '30px', 'background-color': '#EBEBEB'}">
</div>
    

