import { AfterViewInit, Component, EventEmitter, Input, OnInit, Optional, Output, OnDestroy, NgZone, ViewChild, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { BackendService, SearchServicePointsResult, ServicePoint, Countries, Country, PageParameter,ResultLoadType,ResultsParameter } from '../backend.service';
import { AdvanceSearchCriteria } from '../common/advance-search-criteria';
import { Hide } from '../common/hide';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SettingsService } from '../settings.service';
import { environment } from '../../environments/environment';
import { Utils } from '../common/utils';
import { NavigateService } from '../common/navigate.service';
import { AddressEvent, AddressEventType } from '../address-input/address-input.component';
import { Subscription } from 'rxjs';
import { JsApiComponentBase } from '../common/jsapi-component-base';
//import { ResultsPageComponent } from '../results-page/results-page.component';
import { CapabilitySelection } from '../common/capability-selection';
import { SeoService } from '../seo/seo.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalCacheService } from '../local-cache.service';
import { pairwise, startWith } from 'rxjs/operators';
import { ConsentService } from '../consent.service.ts.service';

declare const window: any;
declare const $: any;
declare const OneTrust: any;

@Component({
    selector: 'app-result-list',
    templateUrl: './result-list.component.html',
    styleUrls: ['./result-list.component.scss']
})
export class ResultListComponent extends JsApiComponentBase implements OnInit, AfterViewInit, OnDestroy {

    @Input() resultsPage: boolean = null;

    @Output() onToggleAdvancedSearch = new EventEmitter<boolean>();

    countries: Countries;
    selectedCountry = new UntypedFormControl();
    address = new UntypedFormControl('');
    loading = false;
    servicePoints: SearchServicePointsResult;
    mobile: boolean;
    locationButton = 'N';
    prevCountry:Country = null
    //isLoading: boolean = false;

    selectedServicePoint: ServicePoint;

    capability: CapabilitySelection;
    advancedSearchCriteria: AdvanceSearchCriteria;
    filterOpened = false;
    advancedOpened = false;

    hide: Hide;
    private suggestionPickedAddressEvent: AddressEvent;
    private sessionTokenAddressEvent: AddressEvent;
    private subscriptions: Array<Subscription> = [];

    private resultLoadType: ResultLoadType = ResultLoadType.DEFAULT;

    isMobile = Utils.isMobile();

    mapType:String = 'Google';

    isjsApiSubcriptionInitialize: boolean = false;
    isjsApiInitialize: boolean = false;

    handledInCountrySelect: boolean = false;
    disablePPC : boolean = false;

    queryParams: Params;

    private isCountryPopulatedByAddress: boolean = false;

    public isCheckBoxDisabled: boolean = false;

    public disabled: boolean = false;

    public isSearchButtonNeedToHide: boolean = false;

    public errorText = 'spl.result.error.no_searchresults';

    private initialLoad: boolean = false;

    constructor(@Optional() private route: ActivatedRoute, private backend: BackendService, public settings: SettingsService, 
    private navigate: NavigateService, private router: Router, public ngZone: NgZone, private utils: Utils,private cd: ChangeDetectorRef, 
    private seoService: SeoService, private translateService: TranslateService, private cache: LocalCacheService, private renderer: Renderer2,
    private consentService: ConsentService) {
        super();
        this.countries = this.settings.countries;
        this.hide = new Hide();
        this.capability = new CapabilitySelection(this.settings, this.utils, this.hide, true);
        this.advancedSearchCriteria = new AdvanceSearchCriteria(this.settings, this.utils, this.hide, true);
    }

    ngOnInit() {
        this.isjsApiInitialize = true;
        this.settings.isMapMovedToSea = false;
        this.initialLoad = true;
        this.initializeSubscriptions();
        this.resultLoadType = ResultLoadType.DEFAULT;
        if (this._isOpen) {
            this.initialize();
        }
        this.setSeoFields(this.address.value);
        this.address.valueChanges.subscribe(value => {
            this.setSeoFields(value);
        });
        /*if (this.settings.isBannerSuppresedClient(this.route.snapshot.queryParams)) {
            this.renderer.addClass(document.body, 'hide-onetrust-elements');
        }*/
        if (Utils.isJsApi()) {
            this.loadScript(this.settings.appConfig.cookiesDataDomainUrl).then(() => {
                // Do something after the script is loaded
            }).catch(error => {
                console.error('Failed to load script:', error);
            });
        }
    }

    setSeoFields(address) {
        if(address) {
            this.seoService.setPageTitle(this.parseAndSetTranslation('spl.result.seo.page_title', 'searched_address', address));
            this.seoService.updateTags([{ name: 'description', content:  this.parseAndSetTranslation('spl.result.seo.meta_desc', 'searched_address', address)},
            { name: 'keywords', content: this.parseAndSetTranslation('spl.result.seo.meta_keywords', 'searched_address', address)}]);
        }        
    }

    parseAndSetTranslation(transKey: string, parseKey, value) {
        return `${this.translateService.instant(transKey)}`.replace(`{{${parseKey}}}`, value);
    }

    onOpen() {
        this.isjsApiInitialize = true;
        this.initialize();
        this.resultLoadType = ResultLoadType.DEFAULT;
    }

    private initializeSubscriptions() {
        this.subscriptions.push(this.settings.searchResultLoaded.subscribe(params => {
            let queryParams: Params;
            if (Utils.isJsApi()) {
                // To Do ???
                /*if (!this.settings.searchResult) {
                    this.settings.setSearchResultJsApi(window.gspl.resultListServicePoints);
                }*/
                queryParams = window.gspl.params;
                this.settings.initializeParamsJsApi(queryParams);
            } else {
                queryParams = this.settings.getParams(this.route.snapshot.queryParams);
            }
            this.capability.queryParams = queryParams;
            this.isjsApiInitialize = false;
            this.isjsApiSubcriptionInitialize = true;
            this.initialize();
        }));
        this.subscriptions.push(this.settings.uomChanged.subscribe((uom) => {
            let weightUom: string;
            let dimensionsUom: string;
            if (uom.code === 'km') {
                weightUom = 'kg';
                dimensionsUom =  'cm';
            } else if (uom.code === 'mi') {
                weightUom = 'lb';
                dimensionsUom =  'in';
            }
            const queryParams = this.settings.getParams(this.route.snapshot.queryParams);
            this.router.navigate(['/results'], { 
                queryParams: Object.assign({}, queryParams, {
                    resultUom: uom.code,
                    weightUom: weightUom,
                    dimensionsUom: dimensionsUom
                }) 
            });
        }));
        this.subscriptions.push(this.settings.servicePointSelected.subscribe(selectedServicePoint => {
            if (selectedServicePoint) {
                if(this.servicePoints && this.servicePoints.servicePoints) {        // 793779 - Start
                    this.servicePoints.servicePoints.forEach(svp => {
                        if (svp.facilityId != selectedServicePoint.facilityId) {
                            svp.isSelected = false;
                        }
                    }); // 793779 - End
                }
                if (selectedServicePoint.selectType === Utils.PIN_SELECT_TYPE) {
                    this.selectedServicePoint = selectedServicePoint;
                }
                if (selectedServicePoint && selectedServicePoint.facilityId && document.getElementById(selectedServicePoint.facilityId)){
                    document.getElementById(selectedServicePoint.facilityId).scrollIntoView({ behavior: "smooth", block: "start" });
                }
                
            } else {
                this.selectedServicePoint = null;
                if(this.servicePoints && this.servicePoints.servicePoints) {        // 793779 - Start
                    this.servicePoints.servicePoints.forEach(svp => {
                        svp.isSelected = false;
                    });//to unselect the result-item when moved or dragged
                }
            }
        }));
        this.subscriptions.push(this.settings.mapMovedOrZoomed.subscribe(status => {
            this.ngZone.run(() => { this.loading = status; });
        }));

        this.subscriptions.push (this.address.valueChanges.pipe(startWith(null as string), pairwise()).subscribe(([prev, next]: [any, any]) => {
            /*if(Utils.isAddressAnID(prev) && !Utils.isAddressAnID(next)){
                if(!this.advancedOpened){
                    this.advancedOpened = true;
                }
            }
            if(!Utils.isAddressAnID(prev) && Utils.isAddressAnID(next)){
                this.capability.clear();
                this.advancedSearchCriteria.clear();
            }*/
            this.disableCriteriaSection();
        }));
    }

    disableCriteriaSection() {
        if (this.selectedCountry && this.selectedCountry.value) {
            if (this.isAddressLongEnough() && !this.isID()) {
                this.disabled = false;
                this.disablePPC = false;
            } else {
                if (this.address.value && !this.isID()) {
                    this.disablePPC = false;
                    if (!((this.capability.payment && this.capability.payment.value != '0') || (this.capability.collect && this.capability.collect.value != '0') || (this.capability.service && this.capability.service.value != '0'))) {
                        if (!this.advancedOpened) {
                            this.advancedOpened = true;
                        }
                    }
                } else {
                    if (!this.advancedSearchCriteria.isLimitationEntered() || this.isID()) {
                        this.advancedSearchCriteria.clear();
                    }
                    this.disabled = true;
                    this.disablePPC = true;
                    this.capability.clear();
                    if (!this.advancedOpened && !this.isID()) {
                        this.advancedOpened = true;
                    }
                }
            }
        } else {
            this.disabled = true;
            this.disablePPC = true;
        }
    }

    checkForValidCapability(){
        if(this.selectedCountry && this.selectedCountry.value && !this.selectedCountry.value.secondLevel){
            if(this.capability && this.capability.service && this.capability.service.value != 0){
                    return true;
            }
        }else if(this.selectedCountry && this.selectedCountry.value && this.selectedCountry.value.secondLevel){
            if( this.capability && ((this.capability.payment && this.capability.handling && this.capability.payment.value != 0 && this.capability.handling.value != 0) || (this.capability.collect && this.capability.collect.value != 0))){
                return true;
            }
        }
        return false;
    }

    private initialize() {
        this.loading = false;
        //this.settings.isQrCodeAccessed = false;
        let queryParams: Params;
        if (Utils.isJsApi()) {
            if (!this.settings.searchResult) {
                this.settings.setSearchResultJsApi(window.gspl.resultListServicePoints);
            }
            queryParams = window.gspl.params;
            this.settings.initializeParamsJsApi(queryParams);
        } else {
            queryParams = this.settings.getParams(this.route.snapshot.queryParams);
        }
        this.mobile = (Utils.isMobile() && this.resultsPage) ? true : false;
        this.queryParams = queryParams;   
        this.settings.cookieSettingForLang();

        if (this.settings.searchResult) {
            this.servicePoints = this.settings.filteredSearchResult;
             //temporary - 709541
            /*if (this.servicePoints.servicePoints) {
                this.servicePoints.servicePoints.forEach((sp) => {
                    //sp.svpStatus = 'S';
                    if(sp.facilityId.startsWith("ABZ0")){
                    //sp.servicePointCapabilities.labelFree = true;
                    }
                });
            }*/
            this.selectedServicePoint = null;
            this.loading = false;
            if (!this.selectedCountry.value || ((<Country>this.selectedCountry.value).value != this.servicePoints.selectedCountry.value)) {
                //|| ((<Country>this.selectedCountry.value).value != this.queryParams.countryCode)) { //commented out as it introduces ID: issue
                //if(Utils.isJsApi() && !window.gspl.wsbSearch){
                //    this.isjsApiSubcriptionInitialize = true;
                //}
                /*var newCountry = undefined; //commented out as it introduces ID: issue
                if(this.queryParams.countryCode) {
                    newCountry = this.settings.countries.countryList.find((country)=>country.value.toLowerCase() === this.queryParams.countryCode.toLowerCase());
                }*/
                //this.selectedCountry.setValue(newCountry ? newCountry : this.servicePoints.selectedCountry); //commented out as it introduces ID: issue
                this.selectedCountry.setValue(this.servicePoints.selectedCountry); 
                this.settings.resolveUomOnDetectCountry(); //739690
                this.prevCountry = this.servicePoints.selectedCountry;
            }
            if (this.servicePoints.status && this.servicePoints.status.statusCode !== 300) {
                if(this.initialLoad && this.servicePoints.resultsParam && this.servicePoints.resultsParam.latitude && this.servicePoints.resultsParam.longitude) {
                    this.address.setValue(this.servicePoints.resultsParam.latitude+","+this.servicePoints.resultsParam.longitude);
                } else if (this.servicePoints.searchAddress) {
                    this.address.setValue(this.servicePoints.searchAddress);
                } else if (this.servicePoints.servicePoints && this.servicePoints.servicePoints.length === 1) {
                    this.address.setValue(this.isIDF() ? (Utils.isJsApi() ? ("IDF:" + this.settings.jsApiPageParam.idf) : (this.queryParams.idf ? ("IDF:" + this.queryParams.idf) : this.queryParams.address.toUpperCase())) : ('ID: ' + this.servicePoints.servicePoints[0].facilityId)); //491256
                } else if (this.servicePoints.searchLocation && this.servicePoints.searchLocation.latitude && this.servicePoints.searchLocation.longitude) {
                    this.address.setValue(this.servicePoints.searchLocation.latitude + ', ' + this.servicePoints.searchLocation.longitude);
                } else if(queryParams){
                    const address = queryParams['address'];
                    if (address) {
                        this.address.setValue(address);
                    }
                }
                this.settings.isApiAddressPreSelected = true;
            } else {
                // From: AdvSearchPageComponent -> addressInputDoneOnInit()
                const address = queryParams['address'];
                if (address) {
                    this.address.setValue(address);
                }

                const servicePointID = queryParams['servicePointID'];
                if (servicePointID) {
                    this.address.setValue('ID: ' + servicePointID);
                }
            }
        }

        if(this.settings.selectedCountry && !this.handledInCountrySelect && (Utils.isJsApi() && !window.gspl.wsbSearch) && !this.isjsApiInitialize){
            this.settings.handleCustomCapabilitiesOnCountryChange(this.settings.selectedCountry).then(()=>{
                this.initializeRemainingValues(queryParams);
            }); 
        } 
        if(!Utils.isJsApi() || (Utils.isJsApi() && window.gspl.wsbSearch) || (Utils.isJsApi() && !window.gspl.wsbSearch && 
            (this.settings.selectedCountry && this.settings.selectedCountry.capabilityCustom && 
                (!this.settings.selectedCountry.capabilityCustom.payments && !this.settings.selectedCountry.capabilityCustom.collects)))){
            this.initializeRemainingValues(queryParams);
        }

        if(this.settings && this.settings.searchResult && 
            this.settings.searchResult.status && 
            this.settings.searchResult.status.statusCode === 600 && 
            this.settings.searchResult.status.statusMessage){
            this.errorText = 'error.invalid.country';
        }
        //this.settings.resolveUomOnResultPage();

        this.initialLoad = false;
    }


    initializeRemainingValues(queryParams: Params, isCapabOnly:boolean = true){
        this.advancedOpened = false;
        this.hide.initialize(queryParams);
        this.capability.initialize(queryParams);
        //(this.isjsApiSubInitialize){
            this.capability.setCapabilityOptions();
            this.capability.resolve(isCapabOnly);
        //}
        this.advancedSearchCriteria.initialize(queryParams);
        this.advancedSearchCriteria.resolve();
        // Introduced below block to add PPC validation 566028
        /*if (this.servicePoints && this.capability.isPpcValidationFailed) {
            this.servicePoints.servicePoints = [];
        }*/
        //
        /*if(Utils.isJsApi() && window.gspl.wsbSearch && Utils.isAddressAnID(this.address.value)){
            this.capability.clear();
        }*/
        if (this.servicePoints) {
            this.advancedSearchCriteria.selectedCountry = this.servicePoints.selectedCountry; // can be refactored to be replaced internally by this.settings.selectedCountry
        }

        const locationButton: string = queryParams['locationButton'];
        if (locationButton && locationButton.toUpperCase() === 'Y') {
            this.locationButton = 'Y';
        }

        this.mapType = (this.servicePoints && this.servicePoints.mapType)?this.servicePoints.mapType:'Google';

        if(Utils.isJsApi()){
            this.settings.handleDefaultQrCodeForJsApi(queryParams);
        }

        var nonQrServicepoints: boolean = false;
        if(this.servicePoints && this.servicePoints.servicePoints){
            this.servicePoints.servicePoints.forEach(elemnt=>{
                if(elemnt.servicePointCapabilities && !elemnt.servicePointCapabilities.labelFree){
                    nonQrServicepoints = true;
                }
            });
        }

        if(this.queryParams.defaultQrCode &&  this.queryParams.defaultQrCode.toLowerCase() == 'y'){
            if(this.settings.enableQRCodeByDefault && !nonQrServicepoints){
                this.settings.acceptQrCodeFilter.setValue(true);
            }
        } else {
            if(!nonQrServicepoints && !this.settings.isQrCodeAccessed && this.settings.qrPPCCodesOnlyInUrl){
                this.settings.acceptQrCodeFilter.setValue(true);
                this.settings.acceptQrCodeFilter.disable({ onlySelf: true });    
            }
        } 
        if(this.isID()){
            this.isCheckBoxDisabled = true;
            if(!nonQrServicepoints){
                this.settings.acceptQrCodeFilter.setValue(true);
            }
            this.settings.acceptQrCodeFilter.disable({ onlySelf: true }); 
        }
        if(this.queryParams.svpStatus){
            this.settings.svpStatus = this.queryParams.svpStatus;
        }//709541
        // RTC - 502831 - start
        
        //setTimeout(()=>{
        if(this.settings.queryParamChanged && !Utils.isJsApi() && isCapabOnly){
            this.settings.queryParamChanged = false;
            this.navigate.routeToSamePage(this.queryParams, this.route, 'language', 'en');
        } else if (!Utils.isJsApi() && this.queryParams['address'] && Utils.isAddressAnID(this.queryParams['address'])){ //739690
            this.navigate.routeToSamePage(this.queryParams, this.route, 'resultUom', this.settings.uom.code); //739690
        } //739690
        //},3000);
        // RTC - 502831 - End

        if(Utils.isJsApi() && !window.gspl.wsbSearch){
            this.setJsApiResultParams();
            //this.isjsApiSubInitialize = true;
        }
        /*if(Utils.isJsApi() && window.gspl.wsbSearch){
            this.cd.detectChanges();
        }*/
        this.isjsApiSubcriptionInitialize = false;
        if(this.address && this.address.value && Utils.isAddressAnID(this.address.value)){
            this.capability.clear();
            this.advancedSearchCriteria.clear();
        }

        this.disableCriteriaSection();

        if(this.hide.selectCountry && this.hide.yourLocation){//to hide search button when country and address are hided
            this.isSearchButtonNeedToHide = true;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    onSearchClick() {
        this.advancedOpened = false;
        this.onToggleAdvancedSearch.emit(this.advancedOpened);
        this.filterOpened = false;
        this.settings.isFilterSelected = false;
        if(!this.settings.isMapMovedToSea){
            this.settings.isSearchClicked = true;//difference between yes and search click
        }
        this.settings.isQrCodeAccessed = false;
        this.settings.isUserInputLocationNeeded = true;
        this.settings.acceptQrCodeFilter.setValue(false);
        this.resultLoadType = ResultLoadType.DEFAULT;
        this.capability.setCapabilityCategory();
        //this.capability.setCapabilityOptions();
        //this.capability.resolve(false);
        //this.settings.jsApiPageParam = this.getPageParameter(true);
        //const pageParam = this.getPageParameter(true);
        this.setJsApiResultParams();
        if (Utils.isJsApi()) {
            this.loading = true;
            //window.gspl.selectedCountry = this.selectedCountry.value;
            //const jsApiParams: Params = this.navigate.getJsApiResultParams(this.settings.jsApiPageParam);
            //const resultsParam = this.backend.queryParamsToSearchParams(jsApiParams, this.settings);
            if (window.gspl.wsbSearch) {
                this.backend.searchServicePoints(this.settings.jsApiPageParam, this.settings).then(searchResult => {
                    this.ngZone.run(() => {
                        this.loading = false;
                        this.settings.searchResult = searchResult;
                        this.settings.notifySearchedResultList();
                        this.settings.notifySearchResultLoaded();
                        this.onSearch.emit(searchResult);
                    });
                });
            } else {
                this.settings.notifySearchedResultList();
                this.loading = false; // needed for research
                this.onSearch.emit(this.settings.jsApiPageParam);
            }
        } else {
            const pageParam = this.getPageParameter(true);
            this.settings.notifySearchedResultList();
            this.onSearch.emit(null);
            this.settings.uomChanged.emit(this.settings.uom);
            this.navigate.toResult(this.settings.getParams(this.route.snapshot.queryParams), pageParam);
        }
    }

    onYesButtonClick() {
        if (!Utils.isJsApi()) {
            this.loading = true;
        }
        this.onSearchClick();
    }

    getPageParameter(hasToBeCompleteCapability: boolean): PageParameter {
        let pageParam: PageParameter = this.advancedSearchCriteria.getPageParameter();
        pageParam.address = this.address.value;
        if(Utils.isAddressAnID(pageParam.address)){
            this.capability.clear();
            this.settings.isUserInputLocationNeeded = false;
        } else {
            pageParam.capability = hasToBeCompleteCapability ? this.capability.getSelectedCodeValid() : this.capability.getSelectedCode();
        }
        if (this.suggestionPickedAddressEvent) {
            this.settings.placeId = this.suggestionPickedAddressEvent.placeId;
            this.settings.providerId = this.suggestionPickedAddressEvent.providerId;
            this.settings.sessionToken = this.sessionTokenAddressEvent.sessionToken;
        }
        return pageParam;
    }

    clear() {
        this.capability.clear();
        this.advancedSearchCriteria.clear();
    }

    onCountrySelect(country: Country) {
        if(!this.isCountryPopulatedByAddress && !this.settings.isSelectCountryFreezed()){//&& (!(Utils.isJsApi() && Utils.isAddressAnID(this.address.value)))
            this.address.setValue("");// 789988 //address is cleared when the country is changed
        } else{
            this.isCountryPopulatedByAddress = false;
        }
        
        if(country && country.capabilityCustom && country.capabilityCustom.payments && country.capabilityCustom.collects){
            //if(!this.isjsApiSubcriptionInitialize){
                this.handledInCountrySelect = true;
                this.settings.handleCustomCapabilitiesOnCountryChange(country).then(()=>{
                    this.onCountryChanged(country);
                });
            //}
        } else {
            this.settings.paymentOptionsCustom = [];
            this.settings.collectOptionsCustom = [];
            this.onCountryChanged(country);
        }
    }

    onCountryChanged(country: Country){
        //if(this.isjsApiSubcriptionInitialize){
        //    this.isjsApiSubcriptionInitialize = false;
        //}
        if(country){
            let defaultStatus: boolean = false;
            if(this.prevCountry && this.prevCountry.value != country.value && this.prevCountry.secondLevel != country.secondLevel){
                //this.capability.payment.setValue(0);
                //this.capability.collect.setValue(0);
                /*if(!country.secondLevel){
                    this.capability.service.setValue(0); // This is only needed if first level country also should not retain
                }*/
                defaultStatus = true;
            }
            this.capability.setCapabilityCategory();
            if(Utils.isJsApi() && !window.gspl.wsbSearch && this.isjsApiSubcriptionInitialize){
                this.initializeRemainingValues(this.queryParams,false);
            } else {
                this.capability.setCapabilityOptions();
                if(this.cache.get("mapMoved") && !Utils.isJsApi()){
                    this.capability.resolve(true,defaultStatus);//795587
                    this.cache.remove("mapMoved");
                }else{
                    this.capability.resolve(false,defaultStatus);
                }
                
                //this.settings.resolveUomOnResultPage();
            }
            
            //this.initializeRemainingValues(this.queryParams,false);
            this.prevCountry = country;
            this.advancedSearchCriteria.selectedCountry = country;
            this.advancedSearchCriteria.updateValueAndValidity();
            if(!this.capability.isCapabilitySelectedWithHide() && !this.settings.isShowAlertMsg && !Utils.isAddressAnID(this.address.value)){
                this.advancedOpened = true;
                //this.resultsPage.toggleAdvancedSearch(true);
                this.onToggleAdvancedSearch.emit(this.advancedOpened);
            }
            this.disableCriteriaSection();
        } else {

        }//678061

        if (!this.settings.isSelectCountryFreezed()) {
            if((this.address.value && Utils.isAddressAnID(this.address.value)) || (this.queryParams.servicePointID && Utils.isAddressAnID(this.queryParams.servicePointID))) {//
                this.advancedOpened = false;
                this.capability.clear();
                this.advancedSearchCriteria.clear();
            }
        }
        this.settings.resolveUomOnCountrySelect();
    }

    onAddressEvent(event: AddressEvent) {
        switch (event.type) {
            case AddressEventType.SUGGESTION_PICKED:
                if (!this.selectedCountry.value) {
                    if (event.placeId) {
                        this.backend.getCountryByPlaceId(event.placeId, 
                            event.providerId, 
                        	this.settings ? this.settings.language.iso2Language : 'eng',
				            this.settings ? this.settings.languageCountry.bingCulture2 : 'en-US',				            
                            event.countryCode, event.sessionToken).then(countryCode => {
                                const country = this.countries.countryList.find(country => country.value === countryCode);
                                this.isCountryPopulatedByAddress = true;
	                            this.selectedCountry.setValue(country);
	                    });
                    }
                }
                this.suggestionPickedAddressEvent = event;
                break;
            case AddressEventType.INPUT_FILLED_ENOUGH: 
            case AddressEventType.INPUT_INSUFFICIENT:
                this.suggestionPickedAddressEvent = null;
                break;
            case AddressEventType.SUGGESTION_LOADED:
                this.sessionTokenAddressEvent = event;
                break;
        }
    }

    onAcceptQrCodeFilterChange() {
        this.settings.isQrCodeAccessed = true;
        if(this.resultLoadType === ResultLoadType.FILTER_SELECTED_ALREADY || 
            this.servicePoints.resultLoadType === ResultLoadType.MAP_PAN_OR_ZOOM){ // RTC 565997 && 566002 - To make sure not extending to 50 results when there is already 50
                this.loading = false;
                this.settings.filterServicePoints();
        } else {
            this.resultLoadType = ResultLoadType.FILTER_SELECTED_ALREADY;
            if(Utils.isJsApi()){
                this.setJsApiResultParams();
                this.settings.jsApiPageParam.filterType="qrcode";
            }
            if(this.mobile){
                this.extendToMaximumResults(); //BR11
            } else {                
                this.settings.filterServicePointsInitial(); // RTC 565997 && 566002 - Extends to 50 results
            }
        }
        /*if (Utils.isJsApi()) {
            const pageParam = this.getPageParameter(true);
            //window.gspl.selectedCountry = this.selectedCountry.value;
            const jsApiParams: Params = this.navigate.getJsApiResultParams(pageParam);
            const resultsParam = this.backend.queryParamsToSearchParams(jsApiParams, this.settings);
            const params = Object.assign({}, resultsParam);
            params['servicePointResults'] = 50;
            if (window.gspl.wsbSearch) {
                /*this.backend.searchServicePoints(resultsParam, this.settings).then(searchResult => {
                    this.settings.notifySearchedResultList();
                    this.settings.searchResult = searchResult;
                    this.settings.notifySearchResultLoaded();
                    this.onFilterSelected.emit(searchResult);
                });*/
            /*} else {
                this.settings.notifySearchedResultList();
                this.onFilterSelected.emit(params);
                
            }
        }*/
    }

    onSpPromotionFilterChange() {
        
        if(this.resultLoadType === ResultLoadType.FILTER_SELECTED_ALREADY || 
            this.servicePoints.resultLoadType === ResultLoadType.MAP_PAN_OR_ZOOM){
            this.loading = false;
            this.settings.filterServicePoints();
        } else {
            this.resultLoadType = ResultLoadType.FILTER_SELECTED_ALREADY;
            if(Utils.isJsApi()){
                this.setJsApiResultParams();
                this.settings.jsApiPageParam.filterType="promotion";
            }
            if(this.mobile){
                this.extendToMaximumResults(); //BR11
            } else {
                this.settings.filterServicePointsInitial(); // RTC 565997 && 566002 - Extends to 50 results
            }
        }
    }

    itemSelected(servicePoint: ServicePoint) {
        if (this.selectedServicePoint) {
            this.selectedServicePoint.isSelected = false;
        }
        this.selectedServicePoint = servicePoint;
        this.selectedServicePoint.selectType = Utils.LIST_SELECT_TYPE;
        this.selectedServicePoint.isSelected = true;
        this.onServicePointSelected.emit(servicePoint);
        this.settings.notifyServicePointSelected(servicePoint);
        if(Utils.isJsApi()){
            this.settings.notifyShareButtonDisabled(this.showShareButtonSvpDetail.toUpperCase());
        }
    }

    selectedLocation(servicePoint: ServicePoint) {
        if (this.servicePoints.selectedLocationServicePoint) {
            this.servicePoints.selectedLocationServicePoint.isSelectedLocation = false;
        }
        servicePoint.isSelectedLocation = true;
        this.servicePoints.selectedLocationServicePoint = servicePoint;
        this.onSelectedLocation.emit(this.servicePoints);
    }

    isID() {
        return this.address.value && Utils.isAddressAnID(this.address.value);
    }

    isIDF() {//491256
        if(Utils.isJsApi()){
            return this.settings.jsApiPageParam && this.settings.jsApiPageParam.idf;
        }else{
            return this.queryParams && ((this.queryParams.address && this.queryParams.address.trim().toUpperCase().startsWith("IDF")) || (this.queryParams.idf ));
        }
    }

    isAddressLongEnough() {
        return this.address.value && this.address.value.length >= environment.minCharsToActivateUi;
    }

    toggleAdvancedSearch() {
        this.advancedOpened = !this.advancedOpened;
        this.onToggleAdvancedSearch.emit(this.advancedOpened);
    }

    //BR11
    spDetailClosed() {
        this.selectedServicePoint.isSelected = false;
        this.settings.notifyServicePointSelected(null);
    }

    ngAfterViewInit(): void {
        const elem = window.document.querySelector('.provide-location2');
        Utils.shrinkText(elem, 10, 25);
        const elem2 = window.document.querySelector('.mat-expansion-panel-header-title');
        Utils.shrinkText(elem2, 10, 25);
        if (Utils.isJsApi() && window.gspl.resultListAfterViewInit) {
            window.gspl.resultListAfterViewInit();
        }
        setTimeout(() => {
            this.consentService.initialize();
        }, 100);
    }

    setJsApiResultParams(){
        const jsApiParams: Params = this.navigate.getJsApiResultParams(this.getPageParameter(true));
        const resultsParam = this.backend.queryParamsToSearchParams(jsApiParams, this.settings);
        this.settings.jsApiPageParam = resultsParam;
    }

    // RTC 565997 && 566002
    extendToMaximumResults() {
        this.loading = true;
        if (!Utils.isJsApi()) {
            const queryParams = this.settings.getParams(this.route.snapshot.queryParams);
            const params = Object.assign({}, queryParams);
            
            /*if(params['servicePointResults']==='50'){
                this.settings.filterServicePoints();
            }*/

            if(params['defaultQrCode'] && params['defaultQrCode'].toLowerCase() == 'y' && this.settings.isQrCodeAccessed){
                //this.settings.defaultQrCodeParameter = false;
                params['capability'] = this.settings.ppcCodesInUrl;
                params['defaultQrCode'] = 'N';
            }

            if(!params['servicePointResults']){
                params['servicePointResults'] = this.settings.appConfig.maxResults;
            } else {
                this.settings.filterServicePoints();
            }

            this.router.navigate(['/results'], { queryParams: params }).then(succeeds => {
                if (!succeeds) {
                    // do nothing
                }
                this.loading = false;
            });
        } else {
            //if (this.settings.jsApiPageParam) { //this.servicePoints.resultsParam earlier
                if (window.gspl.wsbSearch) {
                    if(!window.gspl.params.servicePointResults){
                        this.servicePoints.resultsParam.servicePointResults = this.settings.appConfig.maxResults;
                    } else {
                        this.servicePoints.resultsParam.servicePointResults = window.gspl.params.servicePointResults;
                    }
                    this.backend.searchServicePoints(this.servicePoints.resultsParam, this.settings).then(searchResult => {
                        this.settings.searchResult = searchResult;
                        this.settings.searchResult.resultsParam = this.servicePoints.resultsParam;
                        this.settings.notifySearchResultLoaded();
                        this.loading = false;
                    });
                } else {
                    if(this.settings.jsApiPageParam){
                        if(!window.gspl.params.servicePointResults){
                            this.settings.jsApiPageParam.servicePointResults = this.settings.appConfig.maxResults;
                        } else {
                            this.settings.jsApiPageParam.servicePointResults = window.gspl.params.servicePointResults;
                        }
                    }
                    this.onFilterSelected.emit(this.settings.jsApiPageParam);
                }
            //}
        }
    }

    isJsApi(){
        return Utils.isJsApi();
    }

    isclearFilterNotDisabled() {
        if (this.selectedCountry && this.selectedCountry.value) {
            if (this.capability.isCapabilitySelectedWithHide() || (this.capability.payment && this.capability.payment.value != '0')) {
                return true;
            }
            if (this.advancedSearchCriteria.isLimitationEntered() && !this.address.value) {
                return false;
            } else if (this.advancedSearchCriteria.isLimitationEntered() && this.address.value && !this.isID()) {
                return true;
            }
        }
        return false;
    }

    getClientAppCode() {
        return (this.backend.clientAppCode?(this.backend.clientAppCode.toLowerCase()==='gspl'?true:false):false);
    }

    toggleConsentSettings() {
        if(typeof OneTrust !== 'undefined'){
            OneTrust.ToggleInfoDisplay();
        }
    }

    // JsApi

    @Input('mandatory_indicator') mandatoryIndicator = 'mydhl+';
    @Input('show_country_select') showCountrySelect = 'true';
    @Input('show_address_input') showAddressInput = 'true';
    @Input('show_filter') showFilter = 'true';
    @Input('show_advanced_search') showAdvancedSearch = 'true';
    @Input('show_search_button') showSearchButton = 'true';
    @Input('show_share_svp_detail') showShareButtonSvpDetail = 'Y'; //BR11
    @Input('show_qr_code_selected') showDefaultQRCodeSelected = 'N';

    @Input() get loadServicePoints() {
        return () => {
            this.ngZone.run(() => {
                this.settings.setSearchResultJsApi(window.gspl.resultListServicePoints, true);
            });
        };
    }

    @Input() get loadParams() {
        return () => {
            this.ngZone.run(() => {
                // this.initialize(); ???
                this.settings.initializeParamsJsApi(window.gspl.params);
            });
        };
    }

    @Output() onSearch = new EventEmitter<any>();
    @Output() onServicePointSelected = new EventEmitter<ServicePoint>();
    @Output() onSelectedLocation = new EventEmitter<SearchServicePointsResult>();
    @Output() onFilterSelected = new EventEmitter<any>();
    @Output() onDirections = new EventEmitter<any>();
    @Output() onPrint = new EventEmitter<any>();
    @Output() onWebLink = new EventEmitter<any>();
    @Output() onNavigationLink = new EventEmitter<any>();
    @Output() onFindActiveServicePoints = new EventEmitter<any>();
}