import { Directive, Input, NgZone } from '@angular/core';
import { SettingsService } from '../settings.service';
declare const window;
@Directive()
export abstract class JsApiComponentBase {
    
    _isOpen: boolean = true;
    @Input() set isopen(isOpen: string) {
        if (isOpen === "false") {
            this._isOpen = false;
        }
    }
    get isopen(): string { return this._isOpen ? "true" : "false"; }

    abstract ngZone: NgZone
    abstract settings: SettingsService;
    abstract onOpen(): void;

    @Input() get open() {
        return () => {
            this.ngZone.run(() => {
                this._isOpen = true;
                this.onOpen();
            });
        };
    }

    @Input() get close() {
        return () => {
            this.ngZone.run(() => {
                this._isOpen = false;
            });
        };
    }

    get dir(): string {
        return this.settings.isRTL() ? 'rtl' : 'ltr';
    }

    get textAlignRtl(): string {
        return this.settings.isRTL() ? 'text-align-rtl' : '';
    }

    protected loadScript(url: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            //if (document.querySelector(`script[src="${url}"]`)) {
            if (document.querySelector(`script[src="${url}"]`) || typeof window.OneTrust != 'undefined') {
                // Script already loaded, resolve immediately
                resolve();
            } else {
                const scriptElement = document.createElement('script');
                scriptElement.src = url;
                scriptElement.async = true;
                scriptElement.type = "text/javascript";
                scriptElement.setAttribute('data-document-language', 'true');
                scriptElement.setAttribute('charset', 'UTF-8');
                scriptElement.setAttribute('data-domain-script', this.settings.appConfig.cookiesBannerId);
                scriptElement.setAttribute('nonce', window.gsplNonce);
                scriptElement.onload = () => resolve();
                scriptElement.onerror = () => reject(new Error(`Failed to load script at ${url}`));
                document.body.appendChild(scriptElement);
            }
        });
    }
      
}