import { Component, OnInit, AfterViewInit, Input, EventEmitter, Output, ViewChild, ElementRef, Renderer2, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { BackendService, NavigationMap, ServicePoint, ServicePointCapabilities } from '../backend.service';
import { Utils } from '../common/utils';
import { SettingsService } from '../settings.service';
import { SpPromotionComponent } from '../sp-promotion/sp-promotion.component';
import { ActivatedRoute } from '@angular/router';
import { EncodeDecode } from '../common/encode-decode';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavigationModalComponent } from '../navigation-modal/navigation-modal.component';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ConsentService } from '../consent.service.ts.service';

declare const window: any;

const mockInterval = () => ([{ closingTime: '-', openingTime: '-' }]);

const weekDaysDefault = [
    { dayOfWeek: 'MONDAY', intervals: mockInterval() },
    { dayOfWeek: 'TUESDAY', intervals: mockInterval() },
    { dayOfWeek: 'WEDNESDAY', intervals: mockInterval() },
    { dayOfWeek: 'THURSDAY', intervals: mockInterval() },
    { dayOfWeek: 'FRIDAY', intervals: mockInterval() },
    { dayOfWeek: 'SATURDAY', intervals: mockInterval() },
    { dayOfWeek: 'SUNDAY', intervals: mockInterval() }
];

const intervalsDiffer = (intervals: { closingTime: string; openingTime: string }[],
    intervals2: { closingTime: string; openingTime: string }[]) => {
    if (!intervals || !intervals2 || intervals.length !== intervals2.length) {
        return true;
    }

    if (intervals.find((interval, i) => {
        const interval2 = intervals2[i];
        if (interval.closingTime === interval2.closingTime && interval.openingTime === interval2.openingTime) {
            return false;
        } else {
            return true;
        }
    })) {
        return true;
    }

    return false;
};

const shiftDays = (days: ({ intervals: any; dayOfWeek: string })[], start: number) => {
    const adjusted = (start === 0 ? 7 : start) - 1;
    const firstHalf = days.slice(adjusted);
    const secondHalf = days.slice(0, adjusted);
    return firstHalf.concat(secondHalf);
};

const inconsecutiveDates = (date1, date2) => {
    if (!date1 || !date2) {
        return true;
    }
    const parts1 = date1.split(' ');
    const parts2 = date2.split(' ');
    const month1 = parts1[1];
    const month2 = parts2[1];
    const day1 = Number(parts1[0]);
    const day2 = Number(parts2[0]);

    if (!month1 || !month2 || month1 !== month2 || (day1 + 1) !== day2) {
        return true;
    }
    return false;
};

@Component({
    selector: 'app-sp-detail2',
    templateUrl: './sp-detail2.component.html',
    styleUrls: ['./sp-detail2.component.scss']
})
export class SpDetail2Component implements OnInit, AfterViewInit, OnDestroy {

    @Input() yourLocation: string;
    @Input() searchLocation: any;
    @Input() standalone = false;
    @Input() hrFormat24 = false; // temp...
    @Input() fitScreen = false; // temp...
    @Input() hasPromotion: boolean;

    //isMobile = Utils.isMobile; // only used in SpDetailComponent
    @Input() mobile = false;
    @Input() rtl: boolean; // temp...

    @Input()
    set servicePoint(servicePoint: ServicePoint) {
        this._servicePoint = servicePoint;
        this.byShipmentCount = this._servicePoint.shipmentLimitations?.maxNumberOfPieces?.value;
        this.byShipmentWeight = this._servicePoint.shipmentLimitations?.maxShipmentWeight?.value;
        this.displayDetailsStatus = false;
        this.initialize();
        if (servicePoint.svpStatus && servicePoint.svpStatus != this.settings.appConfig.activeSvpStatusGref) {
            this.displayStatus = true;
            this.showContactDetails = true;
            this.showLimitations = true;
            this.showAdditionalInformation = true;
        } else {
            this.displayStatus = false;
        }

    }
    byShipmentCount: number = 0;
    byShipmentWeight: number = 0;
    displayStatus: boolean = false;
    _servicePoint: ServicePoint;
    displayDetailsStatus: boolean = false;

    displayID:boolean = false;

    naviMapData: NavigationMap[];

    lightVersion = false; // temp...
    showButtons = true;

    openingHours: {
        dayOfWeek2: string,
        dayOfWeek: string,
        intervals: {
            closingTime: string;
            openingTime: string;
            date: string;
        }[]
    }[] = [];

    openingHolidayHours = [];
    paymentOptions = [];
    capabilities = [];
    printDetails = '';
    infoTabType = 'detail';
    showOpeningHours: boolean = true; // added for BR11 S11
    showServicePointCapabilities: boolean = true; // added for BR11 S11
    showContactDetails: boolean;
    showLimitations: boolean;
    showAdditionalInformation: boolean;
    email: String = "";
    private subscriptions: Array<Subscription> = [];
    isConsentProvided = false;
    isJsApi = Utils.isJsApi;
    blinkElementStyle: string = "blinker 1s linear 2";

    @Output() closed = new EventEmitter<string>();

    @ViewChild('mainSection', {static: false, read: ElementRef}) mainSection: ElementRef;
    @ViewChild('detailSection', {static: false, read: ElementRef}) detailSection: ElementRef;
    @ViewChild('promotionSection', {static: false, read: ElementRef}) promotionSection: SpPromotionComponent;
    @ViewChild('tabsSection', {static: false, read: ElementRef}) tabsSection: ElementRef;
    @ViewChild('infoSection', {static: false, read: ElementRef}) infoSection: ElementRef;
    @ViewChild('infotabsection', {static: false, read: ElementRef}) infoTabSection: ElementRef;
    @ViewChild('blinkElement',{static: false, read: ElementRef}) blinkElement: ElementRef;
    //@ViewChild('contactItemSection', null) contactItemSection: ElementRef;

    constructor(private utils: Utils, public settings: SettingsService, private route: ActivatedRoute, private renderer: Renderer2, private modalService: NgbModal, private translateService: TranslateService, private backend: BackendService,private cd: ChangeDetectorRef, private consentService: ConsentService) {
        if (!window.gsplInternal) {
            window.gsplInternal = {};
        }
        this.bindDirectionsFunction();
        this.bindPrintFunction();
        this.bindWeblinkFunction();
    }
   
    filterBasedOnCountry(imageName: string) {
        const countryBasedFilter = this.naviMapData.filter((x) =>
            ((x.platform && x.platform.toLowerCase()) == 'desktop') && ((x.imageName && x.imageName.toLowerCase()) == imageName) &&
            ((x.title && x.title.toLowerCase()) ==
            ((this.settings.selectedCountry && this.settings.selectedCountry.directionType) ? this.settings.selectedCountry.directionType.toLowerCase() : 'Google')));
        this.naviMapData = countryBasedFilter;
    }

    openDirections(){
        window.gsplInternal.openDirections("/directions?data=" + this.printDetails);
    }

    openPrint(){
        window.gsplInternal.openPrint("/print?data=" + this.printDetails);
    }

    openWeblink(){
        window.gsplInternal.openWeblink(this.ensureProtocol(this._servicePoint.contactDetails.linkUri));
    }

    private bindDirectionsFunction() {
        window.gsplInternal.openDirections = (url) => {
            if(this.settings.selectedCountry && this.settings.selectedCountry.dirExtRoute){
                const fromLat = /{{fromLat}}/gi;
                const fromLong = /{{fromLong}}/gi;
                const toLat = /{{toLat}}/gi;
                const toLong = /{{toLong}}/gi;
                if(!this.displayID){
                    this.filterBasedOnCountry('desktop');
                }else{
                    this.filterBasedOnCountry('desktop-id-search');
                }
                var naviUrl = (this.naviMapData && this.naviMapData[0]) ? this.naviMapData[0].link:undefined;
                if(naviUrl){
                    var newUrl = naviUrl
                    .replace(fromLat, ((this.searchLocation && this.searchLocation.latitude) ? this.searchLocation.latitude : ''))
                    .replace(fromLong, ((this.searchLocation && this.searchLocation.longitude) ? this.searchLocation.longitude : ''))
                    .replace(toLat, ((this._servicePoint.geoLocation && this._servicePoint.geoLocation.latitude) ? this._servicePoint.geoLocation.latitude : ''))
                    .replace(toLong, ((this._servicePoint.geoLocation && this._servicePoint.geoLocation.longitude) ? this._servicePoint.geoLocation.longitude : ''));
                    this.utils.openExternalLinks(newUrl);
                }  
            }
            else{
                if (!Utils.isJsApi()){
                    this.utils.openExternalLinks(url, this.route);
                }else{
                    if (window.gspl.params.directionsEncodeData) {
                        this.onDirections.emit(this.printDetails);
                    } else {
                        this.onDirections.emit(this.settings.printData);
                    }
                } 
            }
        }
    } 

    private bindPrintFunction() {
        window.gsplInternal.openPrint = (url) => {
            if (!Utils.isJsApi()) {
                this.utils.openExternalLinks(url, this.route);
            } else {
                if (window.gspl.params.printEncodeData) {
                    this.onPrint.emit(this.printDetails);
                } else {
                    this.onPrint.emit(this.settings.printData);
                }
            }
        };
    }

    private bindWeblinkFunction() {
        window.gsplInternal.openWeblink = (url) => {
            if (!Utils.isJsApi()) {
                this.utils.openExternalLinks(url, this.route);
            } else {
                if (window.gspl.params.openWebLink) {
                    this.utils.openExternalLinks(url);
                }
                this.onWebLink.emit(url);
            }
        };
    }

    ngOnInit() {
        this.settings.isSpDetail2Active = true;
        this.isConsentProvided = (this.settings.selectedCountry && this.settings.selectedCountry.mapType && this.settings.selectedCountry.mapType.toLowerCase() == 'bing') ? this.settings.checkShowMapConsent('bing') : this.settings.checkShowMapConsent('google');
        this.initializeSubscriptions();
        //setTimeout(() => {
        //this.resolveInfoSectionHeight();
        //}, 500);
        this.naviMapData = this.settings.appConfig.navigateMap;
        if(this.settings.appConfig.svpdetailBlinkingText) {
            this.blinkElementStyle = this.settings.appConfig.svpdetailBlinkingText;
        }
    }

    private initializeSubscriptions() {
        this.subscriptions.push(this.settings.navigationLinkClicked.subscribe(url => {
            this.onNavigationLink.emit(url);
        }));
        //BR11 - start
        this.subscriptions.push(this.settings.servicePointSelected.subscribe(selectedServicePoint => {
            if (selectedServicePoint) {
                setTimeout(()=>{this.resolveInfoSectionHeight();this.applyBlinkAnimation();},200);
            } 
            this.cd.detectChanges();
            //BR11 - end
        }));

        this.subscriptions.push(this.settings.promotionBannerClosed.subscribe(() => {
            this.hasPromotion = false;
            setTimeout(() => { this.resolveInfoSectionHeight(); }, 200);
            //BR11 - end
        }));
        this.consentService.initialize();
        this.subscriptions.push(this.consentService.consentChange$.subscribe(consent => {
            this.isConsentProvided = (this.settings.selectedCountry && this.settings.selectedCountry.mapType && this.settings.selectedCountry.mapType.toLowerCase() == 'bing') ? this.settings.checkShowMapConsent('bing') : this.settings.checkShowMapConsent('google');
        }));
    }


    private initialize() {
        this.infoTabType = 'detail';
        this.openingHours = [];
        this.openingHolidayHours = [];
        this.paymentOptions = [];
        this.capabilities = [];
        this.printDetails = '';
        this.showOpeningHours = true; // added for BR11 S11
        this.showServicePointCapabilities = true; // added for BR11 S11
        this.showContactDetails = false;
        this.showLimitations = false;
        this.showAdditionalInformation = false;

        if (!this._servicePoint && Utils.isJsApi()) {
            this.showButtons = false;
            this._servicePoint = window.gspl.servicePointDetail;
        }

        if (this._servicePoint.shippingCutOffTime) {
            this._servicePoint.shippingCutOffTime = this._servicePoint.shippingCutOffTime.includes('000') ?
                this.formatHour(this._servicePoint.shippingCutOffTime) : this._servicePoint.shippingCutOffTime;
        }
        setTimeout(() => {
            const printDetailsObj = {
                address: ((address) => {
                    delete address.html;
                    return address;
                })(this._servicePoint.address),
                location: this._servicePoint.geoLocation,
                spName: this._servicePoint.servicePointName,
                localName: this._servicePoint.localName,
                yourLocation: this.yourLocation,
                searchLocation: this.searchLocation,
                hideMobile: this.settings ? this.settings.mobileHiding : false,
                uom: this.settings ? this.settings.uom.code : 'km',
                mapType: (this.settings && this.settings.selectedCountry) ? this.settings.selectedCountry.directionType : 'google',
                lang: this.settings ? this.settings.language.isoLanguage : 'en',
                langCtry: this.settings ? this.settings.language.countryCode : 'US',
                langTrans: this.settings ? this.settings.codeToUseDirectionsPrint : 'en-GB$Latn',
                // userSearchLocation: this.settings?this.settings.userSearchLocation:undefined,
                showTravelMode: this.settings.showTravelMode,
                defDriveMode: (this.settings && this.settings.selectedCountry) ? this.settings.selectedCountry.defDriveMode : 'DRIVING',
                cookieConsent: this.settings.isCookiesConsentGiven() && this.settings.checkShowMapConsent(this.settings.selectedCountry.directionType),
                countryCode: (this.settings && this.settings.selectedCountry) ? this.settings.selectedCountry.value : this._servicePoint.address.country,
                otCode: this.settings.getOneTrustCode(this.settings.language, this.settings.languageCountry)
            };
            this.printDetails = EncodeDecode.encode(JSON.stringify(printDetailsObj)) + "&clientAppCode=" + this.settings.clientAppCode;
            this.settings.printData = printDetailsObj;
        }, 30);
        const transformOpening = (openingDay) => {
            const result = {
                intervals: [{ openingTime: openingDay.openingTime, closingTime: openingDay.closingTime }],
                ...openingDay
            };
            delete result.openingTime;
            delete result.closingTime;
            return result;
        };

        const groupedByDay = [];
        this._servicePoint.openingHours.openingHours.reduce((_groupedByDay, _opening) => {
            const opening = transformOpening(_opening);

            if (_groupedByDay.length === 0) {
                _groupedByDay.push(opening);
            } else {
                const previousOpening = _groupedByDay[_groupedByDay.length - 1];
                if (opening.dayOfWeek === 'HOLIDAY') {
                } else if (previousOpening.dayOfWeek === opening.dayOfWeek) {
                    previousOpening.intervals.push(opening.intervals[0]);
                } else {
                    _groupedByDay.push(opening);
                }
            }

            return _groupedByDay;
        }, groupedByDay);

        const weekDays = this._servicePoint.workWeekStart !== undefined ?
            shiftDays(weekDaysDefault, this._servicePoint.workWeekStart) : weekDaysDefault;

        const groupedByDayFilled = weekDays.map(day => {
            const openDay = groupedByDay.find(i => i.dayOfWeek === day.dayOfWeek);
            return openDay ? openDay : day;
        });

        groupedByDayFilled.reduce((concatOpenings, opening, i, orig) => {
            if (concatOpenings.length === 0) {
                concatOpenings.push(opening);
            } else {
                const lastOpening = concatOpenings[concatOpenings.length - 1];

                if (intervalsDiffer(lastOpening.intervals, opening.intervals)) {
                    lastOpening.dayOfWeek2 = orig[i - 1].dayOfWeek;
                    concatOpenings.push(opening);
                } else if (i === orig.length - 1) {
                    lastOpening.dayOfWeek2 = opening.dayOfWeek;
                }
            }

            return concatOpenings;
        }, this.openingHours);

        const capabilities = this._servicePoint.servicePointCapabilities;
        this.processPaymentOptions(capabilities);
        this.processCapabilities(capabilities);

        this._servicePoint.openingHours.holidays.open = this._servicePoint.openingHours.holidays.open.filter(interval => interval.from);

        this.utils.getTranslatedMonths().then(months => {
            this._servicePoint.openingHours.holidays.closed.forEach((interval) => {
                const dateFrom = interval.from.split('-');
                const dateTo = interval.to.split('-');
                const sameDay = dateFrom[0] === dateTo[0];
                const sameMonth = dateFrom[1] === dateTo[1];
                dateFrom[1] = months[parseInt(dateFrom[1], 10) - 1];
                dateTo[1] = months[parseInt(dateTo[1], 10) - 1];
                if (sameMonth && !sameDay) {
                    interval.from = dateFrom[0];
                    interval.to = dateTo.join(' ');
                } else {
                    interval.from = dateFrom.join(' ');
                    interval.to = dateTo.join(' ');
                }
            });

            const grouped = [];

            this._servicePoint.openingHours.holidays.open.forEach((interval, i, open) => {
                if (interval) {
                    const date = interval.date.split('-');
                    date[1] = months[parseInt(date[1], 10) - 1];
                    interval.date = date.join(' ');
                    if (open[i - 1] && interval.date === open[i - 1].date) {
                        grouped[grouped.length - 1].intervals.push({
                            openingTime: interval.from,
                            closingTime: interval.to
                        });
                    } else {
                        grouped.push({
                            date: interval.date,
                            intervals: [{ openingTime: interval.from, closingTime: interval.to }]
                        });
                    }
                }
            });
            grouped.reduce((concatOpenings, opening, i, orig) => {
                if (concatOpenings.length === 0) {
                    concatOpenings.push(opening);
                } else {
                    const lastOpening = concatOpenings[concatOpenings.length - 1];

                    if (intervalsDiffer(lastOpening.intervals, opening.intervals) || inconsecutiveDates(lastOpening.date2 ?
                        lastOpening.date2 : lastOpening.date, opening.date)) {
                        concatOpenings.push(opening);
                    } else {
                        lastOpening.date2 = opening.date;
                    }
                }

                return concatOpenings;
            }, this.openingHolidayHours);

        });

        this.showDoSearchQueryParam();
        this.isAddressContainsID();
        //BR11 - 555547
        setTimeout(() => {
            this.constructEmailBody();
        }, 200);

    }

    private processPaymentOptions(capabilities: ServicePointCapabilities) {
        if (capabilities.payWithCreditCard) {
            this.paymentOptions.push({
                code: 'card',
                label: 'spl.result.payment.creditcard'
            });
        }
        if (capabilities.payWithCash) {
            this.paymentOptions.push({
                code: 'cash',
                label: 'spl.result.payment.cash'
            });
        }
        if (capabilities.payWithPayPal) {
            this.paymentOptions.push({
                code: 'paypal',
                label: 'spl.result.payment.paypal'
            });
        }
        if (capabilities.payWithCheque) {
            this.paymentOptions.push({
                code: 'sek',
                label: 'spl.result.payment.cheque'
            });
        }
        if (capabilities.payWithMobile) {
            this.paymentOptions.push({
                code: 'mobile',
                label: 'spl.result.payment.mobile'
            });
        }
        this.reArrangePaymentOptions();
    }

    private reArrangePaymentOptions() {
        var paymentOptionsOriginal: any[] = this.paymentOptions;
        var displayOrder: string[] = (this.settings.appConfig && this.settings.appConfig.svcAvlDisplayOrder) ? this.settings.appConfig.svcAvlDisplayOrder.split(",") : [];
        if(displayOrder && paymentOptionsOriginal){
            this.paymentOptions = [];
            displayOrder.forEach(val => {
                const paymentType = paymentOptionsOriginal.filter(payment=> payment.label === val)[0];
                if(paymentType && paymentType.label) {
                    this.paymentOptions.push(paymentType);
                }
            });
        }
    }

    private reArrangeCapabilities() {
        var capabilitiesOriginal: string[] = this.capabilities;
        var displayOrder: string[] = (this.settings.appConfig && this.settings.appConfig.svcAvlDisplayOrder) ? this.settings.appConfig.svcAvlDisplayOrder.split(",") : [];
        if(displayOrder){
            this.capabilities = [];
            displayOrder.forEach(val => {
                if (capabilitiesOriginal.indexOf(val) > -1) {
                    this.capabilities.push(val);
                }
            });
        }
    }

    private processCapabilities(capabilities: ServicePointCapabilities) {
        capabilities.accountShippers? this.capabilities.push('spl.result.sa.account_shippers'): {};
        capabilities.prepaidShippers? this.capabilities.push('spl.result.sa.prepaid_shippers'): {};
        capabilities.payInStoreShippers? this.capabilities.push('spl.result.sa.instore_shippers'): {};
        capabilities.accountPrepaidShippers? this.capabilities.push('spl.result.sa.account_prepaid_shippers'): {};
        capabilities.accountPayInStoreShippers? this.capabilities.push('spl.result.sa.account_instore_shippers'): {};
        capabilities.prepaidPayInStoreShippers? this.capabilities.push('spl.result.sa.prepaid_instore_shippers'): {};
        capabilities.accountPrepaidPayInStoreShippers? this.capabilities.push('spl.result.sa.account_prepaid_instore_shippers'):{};
        capabilities.shipmentDropOff? this.capabilities.push('spl.result.sa.shipment_drop'): {};
        capabilities.shipmentCollection? this.capabilities.push('spl.result.sa.shipment_collection'):{};
        capabilities.shipmentDropOffAndCollection? this.capabilities.push('spl.result.sa.shipment_drop_collection'): {};
        capabilities.accountPrepaidLabelFree? this.capabilities.push('spl.result.sa.label_free'):{};
        capabilities.accountLabelFree? this.capabilities.push('spl.result.sa.account_label_free'):{};
        capabilities.prepaidLabelFree? this.capabilities.push('spl.result.sa.prepaid_label_free'): {};
        capabilities.prePrintReturnLabel? this.capabilities.push('spl.result.sa.printed_return_label'): {};
        capabilities.accountPrePrintReturnLabel? this.capabilities.push('spl.result.sa.printed_label'):{};
        capabilities.prepaidPrePrintReturnLabel? this.capabilities.push('spl.result.sa.return_label'):{};
        capabilities.labelPrinting? this.capabilities.push('spl.result.sa.instore_label_printing'): {};
        capabilities.accountLabelPrinting? this.capabilities.push('spl.result.sa.account_instore_label_printing'):{};
        capabilities.prepaidLabelPrinting? this.capabilities.push('spl.result.sa.prepaid_instore_label_printing'): {};
        capabilities.packaging? this.capabilities.push('spl.result.sa.packaging'): {};
        capabilities.insurance? this.capabilities.push('spl.result.sa.insurance'): {};
        capabilities.importCharges? this.capabilities.push('spl.result.sa.import_charges'): {};
        capabilities.receiverPaid? this.capabilities.push('spl.result.sa.received_paid') :{};
        capabilities.visaProgram? this.capabilities.push('spl.result.sa.visa_process'): {};
        capabilities.internationalDomesticShipping? this.capabilities.push('spl.result.sa.international_domestic'): {};
        capabilities.internationalShipping? this.capabilities.push('spl.result.sa.international') :{};
        capabilities.domesticShipping? this.capabilities.push('spl.result.sa.domestic'): {};
        capabilities.europeanUnionDomesticShipping?this.capabilities.push('spl.result.sa.european_domestic'):{};
        this.reArrangeCapabilities();
    }

    roundHalf(num) {
        return Math.floor(num * 2) / 2;
    }

    roundToOne(num: number) {
        return this.roundHalf(+(Math.floor(parseFloat(num.toString() + 'e+1')) + 'e-1'));
    }

    roundToZero(num: number) {
        return num.toFixed(0);
    }

    formatHour(time) {
        if (!time || time === '-') {
            return null;
        }

        if (this.hrFormat24) {
            const parts = time.split(':');
            if (parts.length > 1) {
                const hour = parts[0];
                const min = parts[1];
                return hour + ':' + min;
            }
        }
        else {
            if (!(time.includes('AM') || time.includes('PM'))) {
                const parts = time.split(':');
                const hour = parts[0];

                if (hour >= 12) {
                    return (hour === '12' ? hour : hour - 12) + ':' + parts[1] + ' PM';
                } else {
                    return Number(hour) + ':' + parts[1] + ' AM';
                }
            }
        }

        return time;
    }

    getDate(day: any) {
        if (day.date && day.date2 && day.date !== day.date2) {
            const parts1 = day.date.split(' ');
            const parts2 = day.date2.split(' ');
            return parts1[0] + '-' + parts2[0] + ' ' + parts1[1] + ' ' + parts1[2];
        }
        return day.date;
    }

    ensureProtocol(linkUri: string) {
        return linkUri.includes('://') ? linkUri : 'http://' + linkUri;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.resolveInfoSectionHeight();
            this.applyBlinkAnimation();
        }, 200);
        
    }

    applyBlinkAnimation() {
        if (this.blinkElement) {
            const nativeElement = this.blinkElement.nativeElement;
            // Remove and add the class to force the animation
            
            if (nativeElement && nativeElement.style) {
                nativeElement.style.animation = ''; 
                
                //nativeElement.style.display= 'none';
                setTimeout(() => {
                    nativeElement.offsetHeight; // Trigger repaint
                    //nativeElement.classList.add('blink_me');
                    nativeElement.style.animation = this.blinkElementStyle; 
                    //nativeElement.style.display= 'block';
                }, 25);
               
            }
        }
    }

    resolveInfoSectionHeight() { //BR11
        // var promotionHeight = (this.promotionSection.spromotionSection ? this.promotionSection.spromotionSection.nativeElement.offsetHeight : (this.hasPromotion ? 70 : 0));
        // var infoSectionHeight: number =
        //     (this.detailSection.nativeElement.offsetHeight + promotionHeight +
        //         this.tabsSection.nativeElement.offsetHeight) + 106;
        // if (Utils.isJsApi()) {
        //     infoSectionHeight = infoSectionHeight - 106;
        // }
        // this.renderer.setStyle(this.infoTabSection.nativeElement, "height", "calc(100% - " + infoSectionHeight.toString() + "px)");
    }

    doPromotionClosed() {
        this.resolveInfoSectionHeight();
    }
    doTempButtonClosed() {
        this.resolveInfoSectionHeight();
    }

    close() {
        this.closed.emit();
    }

    private showDoSearchQueryParam() {
        var doSearch: string = this.route.snapshot.queryParams['doSearch'];
        if(Utils.isJsApi()){
            doSearch = window.gspl.params.doSearch
        }
        if (doSearch) {
            //'detail'
            if (doSearch === 'detailContacts') {
                this.showContactDetails = true;
                this.scrolldownInfoSection();
            } else if (doSearch === 'detailLimits') {
                this.showLimitations = true; // issue: does not scroll
                this.scrolldownInfoSection();
            } else if (doSearch === 'detailHolidays') {
                this.infoTabType = 'holidays';
            } else if (doSearch === 'route') { //BR11 - 555424
                if (!this.mobile && !this.settings.isParameterizedExtClientCallValidationFailed) {
                    //setTimeout(()=>{
                    window.gsplInternal.openDirections("/directions?data=" + this.printDetails);
                    //},5000);
                } else {
                    if (this.mobile) {
                        this.openNavigate();
                    }
                }
            }
        }
    }

    private isAddressContainsID() {
        if (Utils.isJsApi()) {
            if (this.settings.jsApiPageParam.servicePointID) {
                this.displayDetailsStatus = true;
                this.displayID = true;
            }
        } else {
            const address: string = this.route.snapshot.queryParams['address'];
            const servicePointID: string = this.route.snapshot.queryParams['servicePointID'];
            if ((address && (Utils.isAddressAnID(address))) || servicePointID) {
                this.displayDetailsStatus = true;
                this.displayID = true;
            }
        }
    }

    private scrolldownInfoSection(timeout:number = 1000) {
        setTimeout(() => {
            this.infoSection.nativeElement.scrollTop = 1000;
        }, timeout);
    }

    private scrollUpInfoSection(timeout:number = 1000) {
        setTimeout(() => {
            if (this.showOpeningHours) {
                let multipliedLength = 0;
                for(var val of this.openingHours){
                    for(let i=0 ; i < val.intervals.length; i++){
                        multipliedLength += 20;
                    }
                }
                this.infoSection.nativeElement.scrollTop = 30 + multipliedLength;
            } else{
                  this.infoSection.nativeElement.scrollTop = 30;
            }
        }, timeout);
    }

    openNavigate() {
        const modelRef = this.modalService.open(NavigationModalComponent, {
            windowClass: 'navigation-class',
            centered: true,
            backdrop: 'static',
            container: '#navigModalContainer'
        });
        modelRef.componentInstance.servicePoint = this._servicePoint;

        // if (Utils.isIOS()) {
        //    window.open(`https://showtheway.io/to/${this._servicePoint.geoLocation.latitude},${this._servicePoint.geoLocation.longitude}`);
        //   // window.open(`http://maps.apple.com/?q=${this._servicePoint.geoLocation.latitude},${this._servicePoint.geoLocation.longitude}`);
        // } else {
        //     window.open(`geo:0,0?q=${this._servicePoint.geoLocation.latitude},${this._servicePoint.geoLocation.longitude}(${this._servicePoint.servicePointName})`);
        // }
    }

    onServicePointCapabilitiessExpand(){//495003
        this.showServicePointCapabilities = !this.showServicePointCapabilities
        this.scrollUpInfoSection(0);
    }    

    onContactDetailsExpand(){//495003
        this.showContactDetails = !this.showContactDetails
        this.scrolldownInfoSection(0);
    }

    onLimitationsExpand(){//495003
        this.showLimitations = !this.showLimitations
        this.scrolldownInfoSection(0);
    }

    onAdditionalInformationExpand() {//495003
        this.showAdditionalInformation = !this.showAdditionalInformation
        this.scrolldownInfoSection(0);
    }

    //BR11 - 555547
    constructEmailBody() {
        
        var tempClose = '';
        if(this.displayStatus){
            tempClose = this.translateService.instant('spl.servicepoint.temp_closed');
        }

        const servicePointName = this._servicePoint.servicePointName+": " + this._servicePoint.localName;
        const distance = "(" + this.translateService.instant('spl.details.share.at') + " " + this._servicePoint.distance + "):";
        const location = this.translateService.instant('spl.details.share.location') + " " + (this._servicePoint.distance ? distance : ":");
        const address1 = this._servicePoint.address.addressLine1 ? this._servicePoint.address.addressLine1 : "";
        const address2 = this._servicePoint.address.addressLine2 ? this._servicePoint.address.addressLine2 : "";
        const address3 = this._servicePoint.address.addressLine3 ? this._servicePoint.address.addressLine3 : "";
        const address = address1 + address2 + address3;
        const zipCode = this._servicePoint.address.zipCode ? this._servicePoint.address.zipCode : "";
        const city = this._servicePoint.address.city ? this._servicePoint.address.city : "";
        //const wheelChair = this._servicePoint.servicePointCapabilities.disabledAccess ? this._servicePoint.servicePointCapabilities.disabledAccess : null;
        //const parking = this._servicePoint.servicePointCapabilities.parkingAvailable ? this._servicePoint.servicePointCapabilities.parkingAvailable : null;
        //const wheelChairParking = wheelChair ? (parking ? this.translateService.instant('spl.details.share.wheelnpark') : this.translateService.instant('spl.details.share.wheel')) : (parking ? this.translateService.instant('spl.details.share.parking') : "");

        //const directionsUrl = "Directions : " + window.location.protocol + '//' + window.location.host + "/directions?data=" + this.printDetails+ "\n";
        //console.log("this._servicePoint.shippingCutOffTime",this._servicePoint.shippingCutOffTime);
        var servicesAvailable = this.translateService.instant('spl.result.services_available') + ":";
        if (this._servicePoint.shippingCutOffTime) {
            servicesAvailable = servicesAvailable + this.translateService.instant('spl.result.ship_today').toUpperCase().replace('{0}', this._servicePoint.shippingCutOffTime);
        }
        var capabilitiesString = "";
        this.capabilities.forEach(element => {
            var translatedText = this.translateService.instant(element);
            capabilitiesString = capabilitiesString + "\n" + "    o " + (translatedText ? translatedText : element);
        });

        var openingHoursText = "";
        this.openingHours.forEach(element => {
            var dayOfWeek = this.translateService.instant('spl.result.weekday_short_' + element.dayOfWeek.toLowerCase());
            var intervalText = "";
            if (element.dayOfWeek2 && element.dayOfWeek2 !== element.dayOfWeek) {
                dayOfWeek = dayOfWeek + " - " + this.translateService.instant('spl.result.weekday_short_' + element.dayOfWeek2.toLowerCase());
                element.intervals.forEach(interval => {
                    intervalText = intervalText + this.formatHour(interval.openingTime) + " - " + this.formatHour(interval.closingTime) + ", ";
                });
            } else {
                element.intervals.forEach(interval => {
                    intervalText = intervalText + this.formatHour(interval.openingTime) + " - " + this.formatHour(interval.closingTime) + ", ";
                });
            }
            dayOfWeek = dayOfWeek + "     " + intervalText.substring(0, intervalText.length - 2).replace('null', '');
            openingHoursText = openingHoursText + "\n" + "    o " + dayOfWeek.replace('null', '');
        });
        openingHoursText = this.translateService.instant('spl.details.opening_hours') + ":" + tempClose + (this.displayStatus ? "" : openingHoursText);
        
        var openHoursHoliday = this.translateService.instant('spl.result.tab.holidays') + ":";
        var openingHoursHolidayText = "";
        if (this._servicePoint.openingHours.holidays.open.length > 0) {
            this.openingHolidayHours.forEach(element => {
                var openHoursValue = this.getDate(element);
                /*element.intervals.forEach(element1 => {
                    openHoursValue = openHoursValue + this.formatHour(element1.openingTime) +" - " + this.formatHour(element1.closingTime)+","
                });*/
                openHoursValue = openHoursValue.substring(0, openHoursValue.length);
                openingHoursHolidayText = openingHoursHolidayText + ", " + openHoursValue;
            });
            openingHoursHolidayText = "\n" + "    o " + this.translateService.instant('spl.details.opening_holidays_hours') + ": " + openingHoursHolidayText.substring(1);
        }
        var closedHolidayText = "";
        if (this._servicePoint.openingHours.holidays.closed.length > 0) {
            this._servicePoint.openingHours.holidays.closed.forEach(element => {
                var closedHours = "";
                if (element.from !== element.from) {
                    closedHours = element.from + " - " + element.to
                } else {
                    closedHours = element.from
                }

                closedHolidayText = closedHolidayText + ", " + closedHours;
            });
            closedHolidayText = "\n" + "    o " + this.translateService.instant('spl.details.closed_holidays_hours') + ": " + closedHolidayText.substring(1);
        }
        openHoursHoliday = openHoursHoliday + openingHoursHolidayText + closedHolidayText;
        var contactDetails = "";
        if (this._servicePoint.contactDetails.phoneNumber) {
            //this.translateService.instant('spl.result.telephone_contacts')+":"+"\n";
            contactDetails = "\n" + "\n" + "> " + contactDetails + this.translateService.instant('spl.contactdetails.phone') + ": " + this._servicePoint.contactDetails.phoneNumber;
        }
        /*if(this._servicePoint.contactDetails.linkUri){
            contactDetails = contactDetails+"    o "+this.translateService.instant('spl.contactdetails.www')+": "+(this._servicePoint.contactDetails.linkUri)+"\n";
        }*/
        //contactDetails = contactDetails+"    o "+this.translateService.instant('spl.contactdetails.servicepoint.id')+": "+this._servicePoint.facilityId;
        /*var packagingParamters = this.translateService.instant('spl.search.packaging_parameters')+":"+"\n";
        packagingParamters = packagingParamters+"    o "+ this.translateService.instant('spl.details.share.maxpiecedims')+": "+ 
        this.roundToOne(this._servicePoint.servicePointCapabilities.pieceDimensionsLimit.L)+"x"+
        this.roundToOne(this._servicePoint.servicePointCapabilities.pieceDimensionsLimit.W)+"x"+
        this.roundToOne(this._servicePoint.servicePointCapabilities.pieceDimensionsLimit.H)+
        this._servicePoint.servicePointCapabilities.pieceDimensionsLimitUnit.toLowerCase()+"\n";
        packagingParamters = packagingParamters+"    o "+this.translateService.instant('spl.details.share.maxpieceweight')+": "+ 
        this.roundToZero(this._servicePoint.servicePointCapabilities.pieceWeightLimit)+
        this._servicePoint.servicePointCapabilities.pieceWeightLimitUnit.toLowerCase()+"\n";
        packagingParamters = packagingParamters+"    o "+this.translateService.instant('spl.details.share.maxpieces')+ ": "+
        this._servicePoint.servicePointCapabilities.pieceCountLimit+ 
        this.translateService.instant('spl.result.limit.pieces.uom')+"\n";
        packagingParamters = packagingParamters+"    o "+ this.translateService.instant('spl.details.share.maxshipweight')+": "+
        this.roundToZero(this._servicePoint.servicePointCapabilities.pieceWeightLimit)+
        this._servicePoint.servicePointCapabilities.pieceWeightLimitUnit.toLowerCase();*/
        //Find directions and other details: https://locator.dhl.com/results?servicePointID=SGE098
        var otherDetails = "";
        if (!Utils.isJsApi()) {
            otherDetails = this.translateService.instant('spl.details.share.otherdetails') + ": " + window.location.protocol + "//" + window.location.host + '/results?servicePointID=' + this._servicePoint.facilityId + '&clientAppCode=' + this.backend.clientAppCode;
        } else {
            otherDetails = this.translateService.instant('spl.details.share.otherdetails') + ": " + window.gspl.backendUrl + '/results?servicePointID=' + this._servicePoint.facilityId + '&clientAppCode=' + this.backend.clientAppCode;
        }
        //var anotherServicePoint = window.location.href
        /*anotherServicePoint=this.translateService.instant('spl.details.share.anothersp')+": "+anotherServicePoint;*/


        var bodyText:any = "body=" + encodeURIComponent( tempClose + (this.displayStatus ? ("\n" +"\n") : "" ) + "> "+servicePointName + "\n" +"\n"+ "> "+
            location + "\n" +"    o "+ address + ", " + zipCode + " " + city
            /*(wheelChairParking?("\n" +"    o "+ wheelChairParking):"") */+ "\n" +"\n"+ "> "+
            /*directionsUrl + "\n" + */servicesAvailable +  
            capabilitiesString+"\n"+"\n"+ "> "+ openingHoursText +" " /*"\n" +"\n"+ "> "+
            openHoursHoliday+*/ + ""+ contactDetails + /*"\n" +"\n"+ "> "+
            packagingParamters+*/ "\n" +"\n"+ "> "+otherDetails/*+"\n" +"\n"+ "> "+anotherServicePoint*/);

            this.email = "mailto:?subject="+ (this._servicePoint.servicePointName + (this.displayStatus ? " - " : "")+ tempClose) +"&" + bodyText;

        return this.email;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    // JsApi
    @Input('show_share_svp_detail') showShareButtonSvpDetail = 'Y';
    @Output() onDirections = new EventEmitter<any>();
    @Output() onPrint = new EventEmitter<any>();
    @Output() onWebLink = new EventEmitter<any>();
    @Output() onNavigationLink = new EventEmitter<any>();
    @Output() onFindActiveServicePoints = new EventEmitter<any>();
}
