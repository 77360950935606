import { Component, EventEmitter, OnInit, Optional, Output, OnDestroy, AfterViewInit, ViewChild, NgZone, Input, Renderer2 } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ServicePoint, PageParameter, SearchServicePointsResult } from '../backend.service';
import { skip } from 'rxjs/operators';
import { SettingsService } from '../settings.service';
import { Utils } from '../common/utils';
import { Subscription } from 'rxjs';
import { NavigateService } from '../common/navigate.service';
import { ResultListComponent } from '../result-list/result-list.component';
import { JsApiComponentBase } from '../common/jsapi-component-base';
import { LocalCacheService } from '../local-cache.service';
import { ConsentService } from '../consent.service.ts.service';

declare const window: any;

export enum MobileDisplay {
    RESULT_LIST = 1 ,
    SP_DETAIL,
    MAP
}

@Component({
    selector: 'app-results-page',
    templateUrl: './results-page.component.html',
    styleUrls: ['./results-page.component.scss']
})
export class ResultsPageComponent extends JsApiComponentBase implements OnInit, AfterViewInit, OnDestroy {

    MobileDisplay = MobileDisplay;
    mobileDisplay = MobileDisplay.RESULT_LIST;
    prevMobileDisplay: MobileDisplay;
    isMapLoaded = false;
    selectedServicePoint: ServicePoint;
    isMobile = Utils.isMobile;
    isAdvancedSearchOpen = false;
    isJsApi =  Utils.isJsApi;
    optanon: boolean = true

    mobileDisplay1() {
        return this.mobileDisplay;
    }

    @ViewChild('resultList', {static: false}) resultList: ResultListComponent;

    private pageParam: PageParameter;
    private subscriptions: Array<Subscription> = [];

    constructor(@Optional() public route: ActivatedRoute, @Optional() public settings: SettingsService, private navigate: NavigateService, 
    public ngZone: NgZone, private router:Router, private cache: LocalCacheService, private renderer: Renderer2, private consentService: ConsentService) {
        super();
        if (this.settings.toReloadResultsPage) {
            this.cache.set("pageReloaded",true,1);
            this._isOpen = false;
            window.location.reload();
        } else {
            if(!Utils.isJsApi() && this.cache.get("pageReloaded")){
                this.settings.pageReloaded = true;
                this.cache.remove("pageReloaded");
            }
        }
    }

    ngOnInit() {
        this.settings.acceptQrCodeFilter.setValue(false); // Added this block for 566018
        this.settings.spPromotionFilter.setValue(false); // Added this block for 566018
        if(!Utils.isJsApi()){
            //this.settings.cookieLinkDisplayStatus = false; //694365
            if(this.settings.isParameterizedExtClientCallValidationFailed){
                this.router.navigate(['/error']);
            }
        }
        
        this.initializeSubscriptions();
        if (this._isOpen) {
            this.initialize();
        }
        /*if (this.settings.isBannerSuppresedClient(this.route.snapshot.queryParams)) {
            this.renderer.addClass(document.body, 'hide-onetrust-elements');
        }*/
        if (Utils.isJsApi()) {
            this.loadScript(this.settings.appConfig.cookiesDataDomainUrl).then(() => {
                // Do something after the script is loaded
            }).catch(error => {
                console.error('Failed to load script:', error);
            });
        }
    }

    onOpen() {
        //this.settings.isApiResultsPageOpenedInitial = true;
    }

    private initializeSubscriptions() {
        this.subscriptions.push(this.navigate.headerIconClicked.subscribe(() => {
            this.pageParam = this.resultList.getPageParameter(false);
            const queryParams = this.settings.getParams(this.route.snapshot.queryParams);
            this.navigate.tolanding(queryParams, this.pageParam);
        }));
        this.subscriptions.push(this.settings.servicePointSelected.subscribe(selectedServicePoint => {
            //console.log("Check for any loop infinite"); //remove this before migrating this to QA
            this.selectedServicePoint = selectedServicePoint;
            if (this.selectedServicePoint) {
                if(this.mobileDisplay != MobileDisplay.SP_DETAIL){
                    this.prevMobileDisplay = this.mobileDisplay;
                    this.mobileDisplay = MobileDisplay.SP_DETAIL;
                }
            } else {
                this.mobileDisplay = this.prevMobileDisplay;
            }
        }));
        this.subscriptions.push(this.settings.servicePointsFilterTriggered.subscribe(servicePoints => {
            if (Utils.isMobile()) {
                this.settings.filteredSearchResult.servicePoints = servicePoints;
                this.isMapLoaded = false;
            }
        }));

        this.subscriptions.push(this.settings.mapMovedonMobile.subscribe( () => {
            setTimeout(() => {
                if(Utils.isMobile()){
                    this.isMapLoaded = true;
                    this.mobileDisplay = MobileDisplay.MAP;
                }
            }, 0);
            
        }));
    }

    private initialize() {
        this.loadingComponent();
        if (!Utils.isJsApi()) {
            this.subscriptions.push(this.route.queryParams.pipe(skip(1)).subscribe((queryParams) => {
                this.loadingComponent();
                if (this.settings.filteredSearchResult) {
                    this.settings.filteredSearchResult.plainAddress = (queryParams.latitude) ? false : true;
                }
            }));
        }

        let queryParams: Params;
        if (Utils.isJsApi()) {
            if (!this.settings.searchResult) {
                this.settings.setSearchResultJsApi(window.gspl.resultListServicePoints);
            }
            queryParams = window.gspl.params;
            this.settings.initializeParamsJsApi(queryParams);
        } else {
            queryParams = this.settings.getParams(this.route.snapshot.queryParams);
        }
        const shareButton: string = queryParams['shareButton'];
        if (shareButton && shareButton.toUpperCase() === 'N') {
            this.showShareButtonSvpDetail = 'N';
        }

        const defaultQrButton: string = queryParams['defaultQrCode'];
        if (defaultQrButton && defaultQrButton.toUpperCase() === 'Y') {
            this.showDefaultQRCodeSelected = 'Y';
        }

        const showTravelMode: string = queryParams['showTravelMode'];
        if (showTravelMode && showTravelMode.toUpperCase() === 'N') {
            this.settings.showTravelMode = false;
        }

        const optanon: string = queryParams['optanon'];
        if (optanon && (optanon.toUpperCase() === 'N' || optanon.toUpperCase() === 'NO')) {
            this.optanon = false;
        }
        
    }

    private loadingComponent(){
        if (!Utils.isJsApi()) {
            this.settings.searchResult = JSON.parse(JSON.stringify(this.route.snapshot.data.results));
            this.settings.notifySearchResultLoaded();
            if (this.settings.filteredSearchResult.isShowInfoBox) {
                this.settings.filteredSearchResult.servicePoints[0].selectType = Utils.LIST_SELECT_TYPE;
                this.settings.notifyServicePointSelected(this.settings.filteredSearchResult.servicePoints[0]);
            }
        }
    }

    ngAfterViewInit() {
        if (Utils.isMobile() && this.mobileDisplay == MobileDisplay.RESULT_LIST) {
            window.scrollTo(0, 0);
        }
        if (Utils.isJsApi() && window.gspl.resultsPageAfterViewInit) {
            window.gspl.resultsPageAfterViewInit();
        }
        setTimeout(() => {
            this.consentService.initialize();
        }, 100);
        // OneTrust Banner reload issue
        if(!Utils.isJsApi() && !this.consentService.isConsentProvided && this.optanon){
            setTimeout(() => { 
                if(document.getElementById("onetrust-consent-sdk")){
                    document.getElementById("onetrust-consent-sdk").style.display='block';
                }
                if(document.getElementById("onetrust-banner-sdk")){
                    const banner = document.getElementById("onetrust-banner-sdk") as HTMLElement;
                    if(banner) {
                        banner.style.display = 'block';
                        banner.style.animation = 'ot-custom-banner-slide 1s linear';
                    }
                }
            }, 1250);
        }
    }

    showMobileList() {
        this.mobileDisplay = MobileDisplay.RESULT_LIST;
    }

    showMobileMap() {
        this.mobileDisplay = MobileDisplay.MAP;
        this.isMapLoaded = true;
    }

    spDetailClosed() {
        this.selectedServicePoint.isSelected = false;
        this.settings.notifyServicePointSelected(null);        
    }

    toggleAdvancedSearch(isAdvancedSearchOpen: boolean) {
        this.isAdvancedSearchOpen = isAdvancedSearchOpen;
        // BR11
        if(this.selectedServicePoint){
            this.spDetailClosed();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    resizeNothing() {}

    _onSearch(data: any) {
        if (this.isMobile()) {
            this.isMapLoaded = false;
        }
        this.onSearch.emit(data);
    }

    _onFilterSelected(data: any) {
        if (this.isMobile()) {
            this.isMapLoaded = false;
        }
        this.onFilterSelected.emit(data);
    }

    _onPanOrZoom(data: any) {
       /* if (this.isMobile()) {//GSPL-59
            this.isMapLoaded = false;
        }*/
        if(this.settings.isFilterSelected){
            this.onFilterSelected.emit(data);
        } else {
            this.onPanOrZoom.emit(data);
        }
    }

    shareButtonDisable(data:any){
        this.showShareButtonSvpDetail = data;
    }

    // JsApi

    @Input('mandatory_indicator') mandatoryIndicator = 'mydhl+';
    @Input('show_country_select') showCountrySelect = 'true';
    @Input('show_address_input') showAddressInput = 'true';
    @Input('show_filter') showFilter = 'true';
    @Input('show_advanced_search') showAdvancedSearch = 'true';
    @Input('show_search_button') showSearchButton = 'true';
    @Input('show_share_svp_detail') showShareButtonSvpDetail = 'Y';
    @Input('show_qr_code_selected') showDefaultQRCodeSelected = 'N';

    @Input() get loadServicePoints() {
        return () => {
            this.ngZone.run(() => {
                //this.settings.isApiResultsPageOpenedInitial = false;
                this.settings.setSearchResultJsApi(window.gspl.resultListServicePoints, true);
                this.settings.setSearchResultJsApi(window.gspl.mapServicePoints, true);
            });
        };
    }

    @Output() onSearch = new EventEmitter<any>();
    @Output() onFilterSelected = new EventEmitter<any>();
    @Output() onServicePointSelected = new EventEmitter<ServicePoint>();
    @Output() onDirections = new EventEmitter<any>();
    @Output() onPrint = new EventEmitter<any>();
    @Output() onWebLink = new EventEmitter<any>();
    @Output() onSelectedLocation = new EventEmitter<SearchServicePointsResult>();
    @Output() onNavigationLink = new EventEmitter<any>();
    @Output() onPanOrZoom = new EventEmitter<any>();
    @Output() onFindActiveServicePoints = new EventEmitter<any>();
}
