import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, AfterViewInit, ElementRef } from '@angular/core';
import {Utils} from '../common/utils';
import {SettingsService} from '../settings.service';
import {BackendService, Countries, Country} from '../backend.service';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CountryInfoWithCode } from '../model/CountryInfo';

declare const window: any;

@Component({
    selector: 'app-country-select',
    templateUrl: './country-select.component.html',
    styleUrls: ['./country-select.component.scss', '../../flags.css']
})
export class CountrySelectComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input() mobile: boolean; // ???
    @Input() countries: Countries;
    @Input() selectedCountry: UntypedFormControl;
    @Input() enableRtl: boolean = true;
    @Input() clearable = true;
    @Input() abbreviated = false; // ???
    @Input() isBasic: boolean = false;

    @Output() selected = new EventEmitter<Country>();

    private subscriptions: Array<Subscription> = [];

    searchFn = (term: string, item: any):boolean => {
        var translatedValue:string = this.translateService.instant('spl.search.country.name_'+item.value);        
        return item.value.toLowerCase().startsWith(term.toLowerCase())
        ||
        item.label.toLowerCase().startsWith(term.toLowerCase())
        || 
        (translatedValue && translatedValue.toLowerCase().startsWith(term.toLowerCase()))
        || //495846
        (item.customCountryCode && item.customCountryCode.split(',').some((item: string) => item.toLowerCase().startsWith(term.toLowerCase())));
    }

    private onChange(country: Country) {
        this.settings.messageDetails = null;
        this.settings.expressMessageWithLink = null;
        this.settings.expressMessage = null;
        this.settings.selectedCountry = country ? country : null;
        this.addAccessibilityForClear();
        this.selected.emit(country ? country : null);
    }

    constructor(public settings: SettingsService, private backend: BackendService, private translateService: TranslateService, private el: ElementRef) {
        /*if (window.innerWidth <= 768) {
            this.abbreviated = true;
        }*/
    }

    ngOnInit() {
        this.subscriptions.push(this.selectedCountry.valueChanges.subscribe(country => {
            this.onChange(country);
        }));
        if (!Utils.isJsApi()) {
            if (!this.selectedCountry.value) {
                this.settings.countryPopulatedByDetectCountry = true;
                this.backend.detectCountry().then(result => {
                    if (result.country !== '00') {
                        const country = this.countries.countryList.find(country => country.value === result.country);
                        this.selectedCountry.setValue(country);
                        if(country && this.settings.isCookiesConsentGiven() && !this.settings.isFunctionalCookiesDisabled()){
                            var countryInfoWithCode: CountryInfoWithCode = {
                                countryCode2: result.country
                            };
                            this.settings.setCookie('sc', JSON.stringify(countryInfoWithCode));//set cookie for detectCountry
                        }
                        this.settings.resolveUomOnDetectCountry();
                        this.settings.resolveUserCountry();
                        /*if (this.cdr) {
                            this.cdr.detectChanges();
                        }*/
                    }
                });
            }else{
                this.settings.resolveUserCountry();
            }
        }else{//for jsApi
            this.settings.resolveUserCountry();
        }
    }
    
    displayCountry(item) {
        var countryTranslate = 'spl.search.country.name_';
        if (item.customValue) {
            countryTranslate = countryTranslate + item.customValue;
        } else {
            countryTranslate = countryTranslate + item.value;
        }
        countryTranslate = (this.settings.isCountryNameROW && this.settings.appConfig.countryNameROWTranslation.find(country => (country === item.value)) &&
            (item.value == (this.settings._userCountry ? this.settings._userCountry.value : '')))
            ? countryTranslate + '_spl' : countryTranslate + '';

        return countryTranslate;

    }

    ngAfterViewInit() {
       this.addAccessibilityForClear();
    }

    addAccessibilityForClear(){
        if (this.el && this.el.nativeElement) {
            const clearButton = this.el.nativeElement.querySelector('.ng-clear-wrapper');
            if (clearButton) {
                clearButton.setAttribute('tabindex', '0');
                clearButton.setAttribute('aria-label', 'Clear the country selection');
                clearButton.setAttribute('role', 'button');
            }
            const inputBox = this.el.nativeElement.querySelector('.ng-input');
            if(inputBox){
                inputBox.setAttribute('role','combobox');
                inputBox.setAttribute('aria-haspopup','listbox');
                inputBox.setAttribute('aria-expanded','false');
            }
            /*setTimeout(() => {
                const dropDownItem = this.el.nativeElement.querySelector('.ng-dropdown-panel-items');
                console.log(dropDownItem+"hello");
                if(dropDownItem){
                    dropDownItem.setAttribute('role','listbox');
                }
            }, 100);*/
            /*const inputElement:HTMLElement = document.getElementById('countrySelect');
            if(inputElement){
                inputElement.removeAttribute('role');
            }*/

            const selectElement:HTMLElement = document.getElementById('country_select');
            if(selectElement){
                selectElement.removeAttribute('role');
            }
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    isJSApi(){
        return Utils.isJsApi();
    }
}
