<div *ngIf="_isOpen" class="container-fluid h-100 adv-gspl-class">
    <app-header class="row position-given"></app-header>
    <div class="row" [ngStyle]="settings.mobileHiding?{}:{}">
        <div [ngClass]="(isJsApi()?'col-md-2':'col-md-3')+' d-none d-md-flex flex-1'"></div>
        <div [ngClass]="'col ' + (isJsApi()?'col-md-8':'col-md-6')+ ' mx-2 mx-md-3 search-page-customization min-width-600' + (settings?.isRTL()?' search-page-customization-rtl':'')">
            <div  class="row my-3">
                <div *ngIf="(!isJsApi() && !isBackDisabled) || (isJsApi() && (enableBack === 'true' || !isBackDisabled))" class="col px-0">
                    <span class="breadcrumbs">
                        <a tabindex="0" class="breadcrumb-blue" (click)="back()">{{'spl.navigation.back' | translate}}</a>
                        &nbsp;&#62;&nbsp;{{'spl.search.advance' | translate}}
                    </span>
                </div>
            </div>
            <!--div class="row d-none d-md-flex">
                <div *canHide="'selectCountry'" class="col-xl-4 col-lg-4 col-sm-4 col-4">
                    <label for="countrySelect">{{'spl.search.your_country' | translate}}
                        <span *ngIf="mandatoryIndicator === 'default'" class="required">*</span>
                        <span *ngIf="mandatoryIndicator === 'mydhl+' && !selectedCountry.value" class="">*</span>
                        <i *ngIf="mandatoryIndicator === 'mydhl+' && selectedCountry.value" class="material-icons text-success font-weight-bold validation-check">done</i>
                    </label>
                </div>
                <div *canHide="'yourLocation'" class="col-xl-8 col-lg-8 col-sm-8 col-8">
                    <label for="addressInput">{{'spl.search.your_location' | translate}}
                        <span *ngIf="mandatoryIndicator === 'default'" class="required">*</span>
                        <span *ngIf="mandatoryIndicator === 'mydhl+' && !isAddressLongEnough()" class="">*</span>
                        <i *ngIf="mandatoryIndicator === 'mydhl+' && isAddressLongEnough()" class="material-icons text-success font-weight-bold validation-check">done</i>
                    </label>
                </div>
            </div>
            <div class="d-md-none d-lg-none d-xl-none provide">
                <span>
                    <label class="mobile" for="addressInput">{{'spl.search.provide_location' | translate}}</label>
                </span>
            </div-->
            <div class="row mb-md-2">
                <!--div *canHide="'selectCountry'" [cssSwitch]="'yourLocation,col-xl-12 col-12,col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 country-container'" class="">
                    <app-country-select [countries]="countries" [selectedCountry]="selectedCountry"
                                        (selected)="onCountrySelect($event)"></app-country-select>
                </div>
                <div *canHide="'yourLocation'" [cssSwitch]="'selectCountry,col-xl-12 col-12,col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8 address-container'" class="">
                    <app-address-input [selectedCountry]="selectedCountry" [address]="address" (events)="onAddressEvent($event)"></app-address-input>
                </div-->
                <div *canHide="'selectCountry'" [ngClass]="'col '+(hide.yourLocation?'col-md-12':'col-md-6')">
                    <div class="row">
                        <div [ngStyle]="{'width': 'calc(100% - 74px)', 'margin-right': '8px'}">
                            <app-country-select [ngClass]="'col '+ (settings?.isRTL()?'pl-2 pr-0':'pl-0 pr-2')+(hide.yourLocation?' selectCountry':'')" [countries]="countries" [selectedCountry]="selectedCountry" (selected)="onCountrySelect($event)"></app-country-select>
                        </div>
                        <div *ngIf="!hide.yourLocation" [ngClass]="'col-auto px-0 ' + (settings?.isRTL()?'pl-md-3':'pr-md-3')" [ngStyle]="{'width': '52px'}">
                            <a canFreezeAnchor="yourLocation" tabindex="0" class="geolocation" (click)="getLocation()" title="{{'spl.search.location.click.title' | translate}}">
                                <img alt="{{'spl.search.location.click.title' | translate}}" class="mx-auto" [src]="settings.backendUrl + '/assets/locationIcon2.svg'">
                            </a>
                        </div>
                    </div>
                </div>
                
                <div class="w-100 mb-3 d-md-none"></div>
                <div *canHide="'yourLocation'" [ngClass]="'col '+(hide.selectCountry?'col-md-12':'col-md-6')">
                    <div class="row">
                        <div *ngIf="hide.selectCountry" [ngClass]="'col-auto px-0 ' + (settings?.isRTL()?'pl-md-3':'pr-md-3')">
                            <a class="geolocation" (click)="getLocation()" title="{{'spl.search.location.click.title' | translate}}">
                                <img alt="{{'spl.search.location.click.title' | translate}}" class="mx-auto" [src]="settings.backendUrl + '/assets/locationIcon2.svg'">
                            </a>
                        </div>
                        <app-address-input [ngClass]="'col ' +(settings?.isRTL()?'pl-2 pr-0 pr-md-0':'pl-0 pl-md-2 pr-2 pr-md-0')+(hide.selectCountry?' yourLocation':'')" [selectedCountry]="selectedCountry" [address]="address" [loading]="geoLoading" [isAdvanced]="true" (events)="onAddressEvent($event)"></app-address-input> <!-- BR 11 - isAdvanced is added-->
                        <div class="col-auto px-0 pt-3 d-md-none" [ngStyle]="{'width': '52px'}">
                            <a [ngClass]="'hint mx-auto '+(settings?.isRTL()?'hint-rtl':'')" [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/blue_info_icon.svg) no-repeat'}" (click)="openDialog()"></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row d-none d-md-flex">
                <div class="col-6"></div>
                <div *ngIf="!hide.yourLocation" class="col-6 pr-0">
                    <a role="link" aria-label="Click for more information" tabindex="0" #iconRef [ngClass]="'hint float-right '+(settings?.isRTL()?'hint-rtl':'')" [ngStyle]="{background: 'url(' + settings.backendUrl + '/assets/blue_info_icon.svg) no-repeat'}" (click)="openDialog()"></a>
                </div>
            </div>
            <app-adv-search-criteria [ngClass]="(disabled?'row cursor-notallowed':'row')" [title]="(disabled? (!isID()? ('spl.search.insert_location' | translate) :('spl.search.service_not_required' | translate)):'')" [isaddressAnID] = "isID()" [capability]="capability" [searchCriteria]="criteria" [mandatoryIndicator]="mandatoryIndicator" [isCapabilitySelected]="capability.isCapabilitySelectedWithHide()" [infoIcon]="'blue'" [disabled]="disabled" [disablePPC]="disablePPC"></app-adv-search-criteria>
            <div class="row my-3 mt-md-4">
                <div aria-disabled="true" [ngClass]="'col-6 my-auto ' + (settings?.isRTL()?'pr-1':'pl-0') + (isclearFilterNotDisabled()?'':' filter-disabled')">
                    <a [attr.tabindex]="isclearFilterNotDisabled() ? '0' : '-1'" [ngClass]="'text-link font-weight-bold '+ (settings?.isRTL()?'float-right':'float-left') + (isclearFilterNotDisabled() ? '' : ' cursor-notallowed')" [title]="(isclearFilterNotDisabled() || isID()) ? '' : ('spl.search.filter_mesage' | translate)" (click)="isclearFilterNotDisabled() ? clear_() : null">{{"spl.search.btn_reset" | translate}}</a>
                </div>
                <div [ngClass]="'col-6 ' + (settings?.isRTL()?'pl-1':'pr-0')">
                    <!-- *ngIf="criteria.isInitialize" -->
                    <button id="searchButton" [ngClass]="'rounded-lg ' + (settings?.isRTL()?'float-left':'float-right')" mat-button
                            [disabled]="searchInProgress ? true : (
                                    ((isID() || (isAddressLongEnough() && capability.isCapabilitySelectedWithHide())) && criteria.isLimitationValid) ? false : true
                                )"
                                (click)="doSearch()" [title]="!isAddressLongEnough()? (isID() ? '' : ('spl.search.insert_location' | translate)) : (capability.isCapabilitySelectedWithHide() ?'': (isID() ? '' : ('spl.search.select_actio' | translate)))">
                                <span>{{"spl.search.btn_text" | translate}}</span>
                    </button>
                </div>
            </div>
            <div *ngIf="isJsApi() && (!settings.isOneTrustLoaded() || getClientAppCode())" class="row my-3 mt-md-4">
                <div class="col-12" [ngClass]="(settings?.isRTL()?'pr-0':'pl-0')">
                    <a id="ot-sdk-btn" [ngClass]="'text-link font-weight-bold '+ (settings?.isRTL()?'float-right':'float-left')" (click)="toggleConsentSettings()" class="ot-sdk-show-settings">Consent Settings</a>
                </div>
            </div>
        </div>
        <div [ngClass]="(isJsApi()?'col-md-2':'col-md-3')+' d-none d-md-flex flex-1'"></div>
    </div>
    <app-footer *ngIf="!settings.mobileHiding && !mobile && !isJsApi()" class="row margin-top" [hasBackground]="false"></app-footer>
    <app-footer *ngIf="settings.mobileHiding && !isJsApi()" [isAdvanced]="true" [ngClass]="'row d-md-flex'" [ngStyle]="{'background-color': '#EBEBEB'}"></app-footer>
</div>
<!--app-footer *ngIf="settings.mobileHiding && !isJsApi()" [isAdvanced]="true" [ngClass]="'row d-md-flex' + (!settings.mobileHiding?' d-none':'')" style="background-color: #EBEBEB;"></app-footer-->
