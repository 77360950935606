import { AfterViewInit, Component, EventEmitter, Input, OnInit, Optional, Output, OnDestroy, NgZone, ViewChild, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { CapabilitySelection } from '../common/capability-selection';
import { AdvanceSearchCriteria } from '../common/advance-search-criteria';
import { Hide } from '../common/hide';
import { UntypedFormControl } from '@angular/forms';
import { BackendService, Countries, Country, PageParameter } from '../backend.service';
import { Utils } from '../common/utils';
import { environment } from '../../environments/environment';
import { SettingsService } from '../settings.service';
import { NavigateService } from '../common/navigate.service';
import { Subscription } from 'rxjs';
import { AddressEvent, AddressEventType } from '../address-input/address-input.component';
import { JsApiComponentBase } from '../common/jsapi-component-base';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { AdvSearchHintComponent } from '../adv-search-hint/adv-search-hint.component';
import { DialogService } from '../common/dialog.service';
import { SeoService } from '../seo/seo.service';
import { TranslateService } from '@ngx-translate/core';
import { pairwise, startWith } from 'rxjs/operators';

declare const window: any;
declare const OneTrust: any;

@Component({
    selector: 'app-adv-search-page',
    templateUrl: './adv-search-page.component.html',
    styleUrls: ['./adv-search-page.component.scss']
})
export class AdvSearchPageComponent extends JsApiComponentBase implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('iconRef', {static: false}) iconRef: ElementRef;

    countries: Countries;
    languages: any;
    selectedCountry = new UntypedFormControl();
    capability: CapabilitySelection;
    criteria: AdvanceSearchCriteria;
    address = new UntypedFormControl('');
    mobile: boolean;

    searchInProgress = false;
    searchingDisabled = true;

    geoLoading = false;

    hide: Hide;
    private suggestionPickedAddressEvent: AddressEvent;
    private sessionTokenAddressEvent: AddressEvent;
    private subscriptions: Array<Subscription> = [];

    private isCountryPopulatedByAddress: boolean = false;

    isBackDisabled : Boolean = true;
    disabled: boolean = false;
    disablePPC : boolean = false;

    constructor(private backend: BackendService, private router: Router, @Optional() public settings: SettingsService, @Optional() private route: ActivatedRoute,
        private navigate: NavigateService, public ngZone: NgZone, private utils: Utils, private snackBar: MatSnackBar, private dialog: MatDialog, private dialogService: DialogService,  private seoService: SeoService, private translateService: TranslateService, private renderer:Renderer2) {
        super();
        this.countries = this.settings.countries;
        this.languages = this.settings.languages;
        this.selectedCountry.setValue(this.settings.selectedCountry);

        this.hide = new Hide();
        this.capability = new CapabilitySelection(this.settings, this.utils, this.hide, true);
        this.criteria = new AdvanceSearchCriteria(this.settings, this.utils, this.hide);
    }

    ngOnInit() {
        this.setSeoFields();
        this.initializeSubscriptions();
        this.settings.cookieSettingForLang();//cookie settings changes
        this.initialize();
        /*if (this.settings.isBannerSuppresedClient(this.route.snapshot.queryParams)) {
            this.renderer.addClass(document.body, 'hide-onetrust-elements');
        }*/
        if (Utils.isJsApi()) {
            this.loadScript(this.settings.appConfig.cookiesDataDomainUrl).then(() => {
                // Do something after the script is loaded
            }).catch(error => {
                console.error('Failed to load script:', error);
            });
        }
    }

    setSeoFields() {
        this.seoService.setPageTitle(this.translateService.instant('spl.adv_search.seo.page_title'));
        this.seoService.updateTags([{ name: 'description', content:  this.translateService.instant('spl.adv_search.seo.meta_desc')},
        { name: 'keywords', content: this.translateService.instant('spl.adv_search.seo.meta_keywords')}]);
    }

    onOpen() {
        this.setSeoFields();
    }

    private initializeSubscriptions() {
        const queryParams = this.settings.getParams(this.route.snapshot.queryParams);
        this.subscriptions.push(this.navigate.headerIconClicked.subscribe(() => {
            const pageParam = this.getPageParameter(false);
            this.navigate.tolanding(queryParams, pageParam);
        }));
        this.subscriptions.push(this.translateService.stream(['spl.search.seo.page_title','spl.search.seo.meta_desc','spl.search.seo.meta_keywords']).subscribe((translatedValue: string) => {
            this.setSeoFields();
        }));
        this.subscriptions.push (this.address.valueChanges.pipe(startWith(null as string), pairwise()).subscribe(([prev, next]: [any, any]) => {
            /*if(!Utils.isAddressAnID(prev) && Utils.isAddressAnID(next)){
                this.capability.clear();
              //  this.criteria.clear(); // asked sid for confirmation- for ID need to clear this if it is present
            }*/
            /*if(this.isAddressLongEnough()){
                this.disabled = false;
            } else {
                this.disabled = true;
                this.capability.clear();
                this.criteria.clear();
            }*/
            this.disableCriteriaSection();
        }));
    }

    disableCriteriaSection(){
        if(this.selectedCountry && this.selectedCountry.value){
            if(this.isAddressLongEnough() && !this.isID()){
                this.disabled = false;
                this.disablePPC = false;
            } else {
                if(this.address.value && !this.isID()){
                    this.disablePPC = false;
                } else {
                    if(!this.criteria.isLimitationEntered() || this.isID()){
                        this.criteria.clear();
                    }
                    this.disabled = true;
                    this.disablePPC = true;
                    this.capability.clear();
                }
            }
        }else{
            this.disabled = true;
            this.disablePPC = true;
        }
    }
    

    private initialize() {
        let queryParams: Params;
        if (Utils.isJsApi()) {
            queryParams = this.settings.jsApiPageAttributes;
            this.settings.initializeParamsJsApi(queryParams);
        } else {
            queryParams = this.settings.getParams(this.route.snapshot.queryParams);
            if (this.settings.isParameterizedExtClientCallValidationFailed) {
                this.router.navigate(['/error']);
            }
            this.isBackDisabled = false;
        }

        if(queryParams && queryParams['hide'] && queryParams['hide'].indexOf('back')>=0) {
            this.isBackDisabled = true;
        }
                
        this.mobile = Utils.isMobile();

        const address = queryParams ? queryParams['address'] : null;
        if (address) {
            this.address.setValue(address);
        }
        const servicePointID = queryParams ? queryParams['servicePointID'] : null;
        if (servicePointID) {
            this.address.setValue('ID: ' + servicePointID);
        }
        if(queryParams.svpStatus){
            this.settings.svpStatus = queryParams.svpStatus;
        }
		
        this.hide.initialize(queryParams);
        this.capability.initialize(queryParams);
        this.capability.setCapabilityOptions();
        this.capability.resolve(true);
        this.criteria.initialize(queryParams);
        this.criteria.resolve();
        this.criteria.selectedCountry = this.settings.selectedCountry; // can be refactored to be replaced internally by this.settings.selectedCountry

        if(this.address && this.address.value && Utils.isAddressAnID(this.address.value)){
            this.capability.clear();
            this.criteria.clear();
        }

        this.disableCriteriaSection();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    onCountrySelect(country: Country) {
        if (!this.isCountryPopulatedByAddress && !this.settings.isSelectCountryFreezed()) {
            this.address.setValue("");
        } else {
            this.isCountryPopulatedByAddress = false;
        }
        if (country && country.capabilityCustom && country.capabilityCustom.collects && country.capabilityCustom.collects) {
            this.settings.handleCustomCapabilitiesOnCountryChange(country).then(() => { this.onCountryChanged(country); });
        } else {
            this.settings.paymentOptionsCustom = [];
            this.settings.collectOptionsCustom = [];
            this.onCountryChanged(country);
        }
    }

    onCountryChanged(country: Country) {
        if (country) {
            this.capability.setCapabilityOptions();
            this.capability.resolve(false);
            this.criteria.selectedCountry = country;
            this.criteria.updateValueAndValidity();
        }
        this.disableCriteriaSection();//to clear capability after country changes
        this.settings.resolveUomOnCountrySelect();//678061
    }

    isAddressLongEnough() {
        return this.address.value && this.address.value.length >= environment.minCharsToActivateUi;
    }

    onAddressEvent(event: AddressEvent) {
        switch (event.type) {
            case AddressEventType.SUGGESTION_PICKED:
                if (!this.selectedCountry.value) {
                    if (event.placeId) {
                        this.backend.getCountryByPlaceId(event.placeId,
                            event.providerId,
                            this.settings ? this.settings.language.iso2Language : 'eng',
                            this.settings ? this.settings.languageCountry.bingCulture2 : 'en-US',
                            event.countryCode, event.sessionToken).then(countryCode => {
                                this.isCountryPopulatedByAddress = true;
                                const country = this.countries.countryList.find(country => country.value === countryCode);
                                this.selectedCountry.setValue(country);
                            });
                    }
                }
                this.suggestionPickedAddressEvent = event;
                break;
            case AddressEventType.INPUT_FILLED_ENOUGH:
            case AddressEventType.INPUT_INSUFFICIENT:
                this.suggestionPickedAddressEvent = null;
                break;
            case AddressEventType.SUGGESTION_LOADED:
                this.sessionTokenAddressEvent = event;
                break;
        }
    }

    /*isCapabilitySelected(): boolean {
        //this.capability.getSelectedCode();
        if (this.hide.mandatoryCapability === MandatoryCapability.DEFAULT_SEND_OR_COLLECT) {
            //return this.criteria.collect.value !== '0' || this.criteria.payment.value !== '0';
            return (this.capability.payment.value !== '0' && this.capability.handling.value !== '0' ) || this.capability.collect.value !== '0';
        } else if (this.hide.mandatoryCapability === MandatoryCapability.NONE) {
            return true;
        } else if (this.hide.mandatoryCapability === MandatoryCapability.SEND_AND_COLLECT) {
            //return this.criteria.collect.value !== '0' && this.criteria.payment.value !== '0';
            return (this.capability.payment.value !== '0' && this.capability.handling.value !== '0' ) && this.capability.collect.value !== '0';
        } else if (this.hide.mandatoryCapability === MandatoryCapability.EITHER_SEND_OR_COLLECT) {
            //return (!(this.criteria.collect.value !== '0' && this.criteria.payment.value !== '0') && 
            //    (this.criteria.collect.value !== '0' || this.criteria.payment.value !== '0'));
            return (!( (this.capability.payment.value !== '0' && this.capability.handling.value !== '0') && this.capability.collect.value !== '0') && 
                ((this.capability.payment.value !== '0' && this.capability.handling.value !== '0' ) || this.capability.collect.value !== '0'));
        } else {
            console.error('AdvSearchPageComponent.hide.mandatoryCapability should not be null');
            return false;
        }
    }*/

    doSearch() {
        this.settings.isResultReachedFromHomeLanding = true;
        this.settings.isUserInputLocationNeeded = true;
        if (this.mandatoryIndicator === 'default') {
            this.searchInProgress = true;
        }
        this.settings.isFilterSelected = false;
        this.settings.isQrCodeAccessed = false;
        const pageParam = this.getPageParameter(true);
        if (Utils.isJsApi()) {
            //window.gspl.selectedCountry = this.selectedCountry.value;
            const jsApiParams: Params = this.navigate.getJsApiResultParams(pageParam);
            const resultsParam = this.backend.queryParamsToSearchParams(jsApiParams, this.settings);
            this.settings.jsApiPageParam = resultsParam;
            this.settings.setJsApiPageAttributes({ capability: resultsParam.capability });
            if (window.gspl.wsbSearch) {
                this.backend.searchServicePoints(resultsParam, this.settings).then(searchResult => {
                    this.settings.searchResult = searchResult;
                    this.onSearch.emit(searchResult);
                });
            } else {
                this.onSearch.emit(resultsParam);
            }
        } else {
            this.navigate.toResult(this.settings.getParams(this.route.snapshot.queryParams), pageParam);
        }
    }

    private getPageParameter(hasToBeCompleteCapability: boolean): PageParameter {
        let pageParam: PageParameter = this.criteria.getPageParameter();
        pageParam.address = this.address.value;
        pageParam.capability = hasToBeCompleteCapability ? this.capability.getSelectedCodeValid() : this.capability.getSelectedCode();
        if (this.suggestionPickedAddressEvent) {
            this.settings.placeId = this.suggestionPickedAddressEvent.placeId;
            this.settings.providerId = this.suggestionPickedAddressEvent.providerId;
            this.settings.sessionToken = this.sessionTokenAddressEvent.sessionToken;
        }
        return pageParam;
    }

    isJsApi() {
        return Utils.isJsApi();
    }

    isID() {
        return this.address.value && Utils.isAddressAnID(this.address.value);
    }

    ngAfterViewInit() {
        if (!Utils.isJsApi()) {
            window.document.body.style.backgroundColor = '#EBEBEB';
            window.scrollTo(0, 0);
        }
        if (Utils.isJsApi() && window.gspl.advancedSearchAfterViewInit) {
            window.gspl.advancedSearchAfterViewInit();
        }
    }

    back() {
        if (!Utils.isJsApi()) {
            this.navigate.notifyHeaderIconClicked();
        } else {
            this.onBack.emit();
        }
    }

    getLocation() {

        const error = (err) => {
            // location fix
            if (err && err.code === 1) {
                this.utils.showTranslatedMsg(this.snackBar, 'spl.geoapi.no.permission');
            } else {
                this.utils.showTranslatedMsg(this.snackBar, 'spl.geoapi.no.geolocation');
            }
        };

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) =>
                this.showPosition(position.coords.latitude, position.coords.longitude),
                error, { maximumAge: 600000, timeout: 10000, enableHighAccuracy: true });
        } else {
            this.utils.showTranslatedMsg(this.snackBar, 'spl.geoapi.not.supported');
        }

    }

    private showPosition(latitude, longitude) {
        this.backend.getAddressSuggestions(latitude + ', ' + longitude,
            this.settings ? this.settings.language.iso2Language : 'eng',
            this.settings ? this.settings.getCurrentLangCode() : 'en-US', null, null,
            true).then(result => {
                if (result && result[0]) {
                    this.ngZone.run(() => {
                        this.isCountryPopulatedByAddress = true;
                        this.address['geoLocated'] = true;
                        this.address.setValue(result[0].label);
                        const country = this.countries.countryList.find(country => country.value === result[0].countryCode);
                        this.selectedCountry.setValue(country);
                        this.geoLoading = false;
                    });
                }
            });
    }

    openDialog(){//565958
        this.dialogService.openDialog(this.dialog, this.iconRef);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if(this.dialogService.isInfoDialogOpen) {
            this.dialogService.updateDialogPosition(this.dialogService.dialogInfoRef, this.dialogService.iconRef);
        }
    }

    clear_() {
        this.capability.clear();
        this.criteria.clear();
    }

    isclearFilterNotDisabled() {
        if(this.selectedCountry && this.selectedCountry.value){
            if(this.capability.isCapabilitySelectedWithHide() || (this.capability.payment && this.capability.payment.value != '0')){
                return true;
            }
            if(this.criteria.isLimitationEntered() && !this.address.value){
                return false;
            }else if(this.criteria.isLimitationEntered() && this.address.value && !this.isID()){
                return true;
            }
        }
        
        return false;
    }

    getClientAppCode() {
        return (this.backend.clientAppCode?(this.backend.clientAppCode.toLowerCase()==='gspl'?true:false):false);
    }

    toggleConsentSettings() {
        if(typeof OneTrust !== 'undefined'){
            OneTrust.ToggleInfoDisplay();
        }
    }

    // JsApi

    @Input('mandatory_indicator') mandatoryIndicator = 'mydhl+';
    @Input('enable_back') enableBack = 'false';

    @Input() get clear() {
        return () => {
            this.ngZone.run(() => {
                this.address.setValue(null);
                this.selectedCountry.setValue(null);
                this.criteria.clear();
            });
        };
    }

    @Output() onSearch = new EventEmitter<any>();
    @Output() onBack = new EventEmitter<void>();
}
